import { InjectionToken, Provider } from '@angular/core';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  combineReducers,
  MetaReducer,
} from '@ngrx/store';
import { AuthActions, ListModel, StateUtils } from '@spartacus/core';
import {
  DoehlerWishListData,
  DoehlerWishListList,
  DoehlerWishListMediaListData
} from 'src/app/doehler-custom-wishlist/core/model/wishlist.model';
import {
  DOEHLER_WISHLIST_ENTITIES,
  DOEHLER_WISHLIST_LIST,
  WishlistState,
  WISHLIST_FEATURE,
  WISHLISTMEDIALIST_FEATURE,
  WishlistMediaListState,
  DOEHLER_WISHLIST_MEDIALIST_ENTITIES,
  DOEHLER_WISHLIST_LIST_MEDIA_LIST
} from '../wishlist-state';
import {
  wishlistEntitiesReducer,
  wishListListMediaReducer,
  wishListListReducer,
  wishlistMediaListEntitiesReducer,
} from './doehler-whishlist.reducer';


export function getReducers(): ActionReducerMap<WishlistState> {
  return {
    [WISHLIST_FEATURE]: combineReducers({
      entities: StateUtils.entityLoaderReducer<DoehlerWishListData>(
        DOEHLER_WISHLIST_ENTITIES,
        wishlistEntitiesReducer
      ),
      list: StateUtils.entityLoaderReducer<ListModel>(
        DOEHLER_WISHLIST_LIST,
        wishListListReducer
      ),
    }),
  };
}

export function getMediaReducers(): ActionReducerMap<WishlistMediaListState> {
  return {
    [WISHLISTMEDIALIST_FEATURE]: combineReducers({
      entities: StateUtils.entityLoaderReducer<DoehlerWishListMediaListData>(
          DOEHLER_WISHLIST_MEDIALIST_ENTITIES,
          wishlistMediaListEntitiesReducer
      ),
      list: StateUtils.entityLoaderReducer<ListModel>(
          DOEHLER_WISHLIST_LIST_MEDIA_LIST,
          wishListListMediaReducer
      ),
    }),
  };
}


export const reducerToken: InjectionToken<ActionReducerMap<
WishlistState
>> = new InjectionToken<ActionReducerMap<WishlistState>>(
  'WishlistReducers'
);

export const reducerMediaListToken: InjectionToken<ActionReducerMap<
    WishlistMediaListState
    >> = new InjectionToken<ActionReducerMap<WishlistMediaListState>>(
    'WishlistMediaListReducers'
);

export const reducerProvider: Provider = {
  provide: reducerToken,
  useFactory: getReducers,
};

export const reducerMediaListProvider: Provider = {
  provide: reducerMediaListToken,
  useFactory: getMediaReducers,
};

export function clearOrganizationState(
  reducer: ActionReducer<WishlistState, Action>
): ActionReducer<WishlistState, Action> {
  return function (state, action) {
    if (action.type === AuthActions.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

/*export function clearMediaListOrganizationState(
    reducer: ActionReducer<WishlistMediaListState, Action>
): ActionReducer<WishlistMediaListState, Action> {
  return function (state, action) {
    if (action.type === AuthActions.LOGOUT) {
      state = undefined;
    }

    return reducer(state, action);
  };
}*/

export const metaReducers: MetaReducer<any>[] = [clearOrganizationState];
//export const metaMediaListReducers: MetaReducer<any>[] = [clearMediaListOrganizationState];
