import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AuthConfigService, AuthHttpHeaderService, AuthService, AuthStorageService, AuthToken, OAuthLibWrapperService, UserIdService } from "@spartacus/core";
import { Observable, of, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

/**
 * 
 * Responsible for catching auth errors and providing `Authorization` header for API calls.
 * Uses AuthHttpHeaderService for request manipulation and error handling. Interceptor only hooks into request send/received events.
 */

 declare let gigya: any;
 declare global {
   interface Window {
     onGigyaServiceReady: any;
   }
 }
 @Injectable({ providedIn: 'root' })
 export class DoehlerAuthInterceptor implements HttpInterceptor {
  gigyaReady: Promise<void>;
   constructor(
     protected authHttpHeaderService: AuthHttpHeaderService,
     protected authConfigService: AuthConfigService,
     protected userIdService: UserIdService,
    protected oAuthLibWrapperService:OAuthLibWrapperService,
    protected authService:AuthService,
    protected authStorageService:AuthStorageService,
    protected store: Store,
     protected http: HttpClient
   ) {


            
 const nextPageUrl = localStorage.getItem('next-page-url');
 localStorage.setItem('next-page-url', nextPageUrl);

 //set callback for the CDC Web SDK loading event and resolve promise
 this.gigyaReady = new Promise((resolve, reject) => {

  window.onGigyaServiceReady = function () {

    resolve();

  };

})

this.gigyaReady.then(() => {

  // set active organization

  if (typeof gigya !== 'undefined') {

    gigya.accounts.b2b.getOrganizationContext({

      callback: function (res) {
        if (res.status === 'FAIL') {
          localStorage.setItem('spartacus-accountID', undefined);
          return false;
       }
        localStorage.setItem('spartacus-accountID', res.bpId);
      },

    });

  } 

});
    var baseUrlWithHash = new URL(window.location.href.replace(/#/g, '?'));

    if (baseUrlWithHash) {
      var token = baseUrlWithHash.searchParams.get('access_token');

      console.log(token);
      
      if (token) {
        
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
        );
        
        var decodeToken = JSON.parse(jsonPayload);

        console.log(decodeToken);

        this.userIdService.setUserId(decodeToken.mail);
        this.userIdService.takeUserId(decodeToken.mail);
        
        let currentToken: AuthToken;
        
        this.authStorageService
        .getToken()
        .subscribe((tok) => (currentToken = tok))
        .unsubscribe(); 

        currentToken.access_token = base64;
        
        this.authStorageService.setToken(currentToken);
        
        let customerID = decodeToken.customerID;
        
        if (customerID && customerID.includes(','))
          customerID = customerID.split(",")[0];


        localStorage.setItem('spartacus-exp', decodeToken.exp);
        localStorage.setItem('spartacus-customerID', customerID);

        this.authService.checkOAuthParamsInUrl();
       

        this.authService.isUserLoggedIn().subscribe((isUserLoggedIn) => {
          console.log(isUserLoggedIn);
          if (isUserLoggedIn) {
            if (nextPageUrl != null)
              window.location.assign(nextPageUrl);
              else  window.location.assign(environment.baseUrl + '/my-account/wishlist');
          } else {
            this.authService.loginWithRedirect();
          }
          
        });

        
       
      }
    }

   }
 
   // @ts-ignore
     intercept(
     request: HttpRequest<any>,
     next: HttpHandler,
     initialToken: AuthToken
   ): Observable<HttpEvent<any>> {
     const shouldCatchError = this.authHttpHeaderService.shouldCatchError(
       request
     );
 
     request = this.authHttpHeaderService.alterRequest(request);
     return next.handle(request).pipe(
       catchError((errResponse: any) => {
        console.log(errResponse)
         if (errResponse instanceof HttpErrorResponse) {
         
          console.log(errResponse.status)
           switch (errResponse.status) {
             case 401: // Unauthorized

               if (this.isExpiredToken(errResponse) && shouldCatchError) {
                   logoutCustomer();
                 
                 return this.authHttpHeaderService.handleExpiredAccessToken(
                   request,
                   next,
                   initialToken
                 );
               } else if (
                 // Refresh expired token
                 // Check that the OAUTH endpoint was called and the error is for refresh token is expired
                
                 errResponse.url.includes(
                   this.authConfigService.getTokenEndpoint()
                 ) &&
                 errResponse.error.error === 'invalid_token'
               ) {
                localStorage.clear();
                sessionStorage.clear();
                 this.authHttpHeaderService.handleExpiredRefreshToken();
                 return of<HttpEvent<any>>();
               }
 
               break;
             case 400: // Bad Request
               localStorage.clear();
               sessionStorage.clear();
               if (
                 errResponse.url.includes(
                   this.authConfigService.getTokenEndpoint()
                 ) &&
                 errResponse.error.error === 'invalid_grant'
               ) {
                 if (request.body.get('grant_type') === 'refresh_token') {
                   this.authHttpHeaderService.handleExpiredRefreshToken();
                 }
               }
               break;
               case 404:
                logoutCustomer();
               break;
           }

          
         }
         return throwError(errResponse);

         function logoutCustomer() {
           const accountID = localStorage.getItem("spartacus-accountID");
           localStorage.clear();
           sessionStorage.clear();
           if (accountID) {
             const nextPageUrl = localStorage.getItem('next-page-url');

             if (nextPageUrl != null) {
               window.location.assign(nextPageUrl);
             }
             else {
               this.authService.coreLogout();
               this.authService.logout();
             }
           }
         }
       })
     );
   }
 
  
   protected isExpiredToken(resp: HttpErrorResponse): boolean {
     return resp.error?.errors?.[0]?.type === 'InvalidTokenError';
   }
 }
 