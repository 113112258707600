import { ListModel, SearchConfig, StateUtils } from "@spartacus/core";
import { DoehlerWishListSearchConfig } from "src/app/app.module";
import {DoehlerWishListData, DoehlerWishListMediaListData} from "../../model";
import {
  DOEHLER_WISHLIST_ENTITIES,
  DOEHLER_WISHLIST_LIST,
  DOEHLER_WISHLIST_LIST_POPUP,
  DOEHLER_WISHLIST_LIST_MEDIA_LIST,
  DOEHLER_WISHLIST_MEDIALIST_ENTITIES
} from "../wishlist-state";

export const LOAD_WISHLIST = '[DoehlerWishListData] Load DoehlerWishListData Data';

export const LOAD_WISHLIST_SUCCESS =
    '[DoehlerWishListData] Load DoehlerWishListData Data Success';
export const LOAD_WISHLIST_MEDIALIST_SUCCESS = '[DoehlerWishListMediaListData] Load DoehlerWishListMediaListData  Success';
export const LOAD_WISHLIST_FAIL = '[DoehlerWishListData] Load Wishlist Data Fail';

export const LOAD_WISHLISTS = '[DoehlerWishListData] Load Wishlists';
export const LOAD_WISHLISTS_POPUP = '[DoehlerWishListData] Load Wishlists Popup';
export const LOAD_WISHLISTSMEDIALIST = '[DoehlerWishListMediaListData] Load WishlistsMediaList';
export const LOAD_WISHLISTS_FAIL = '[DoehlerWishListData] Load Wishlists Fail';
export const LOAD_WISHLISTS_MEDIA_LIST_FAIL = '[DoehlerWishListMediaListData] Load Wishlists Media List Fail';
export const LOAD_WISHLISTS_SUCCESS = '[DoehlerWishListData] Load Wishlists Success';
export const LOAD_WISHLISTS_MEDIALIST_SUCCESS = '[DoehlerWishListMediaListData] Load Wishlists Media List Success';


export const REMOVE_WISHLIST = 'Remove Wishlist ';
export const REMOVE_WISHLIST_SUCCESS =
  'Remove Wishlist  Success';
export const REMOVE_WISHLIST_FAIL = 'Remove Wishlist  Fail';

export const ADD_WISHLIST = 'Add Wishlist';
export const ADD_WISHLIST_FAIL = 'Add Wishlist  Fail';
export const ADD_WISHLIST_SUCCESS = 'Add Wishlist  Success';

export const ADD_WISHLIST_RESET = 'Add Wishlist  Reset';
export const REMOVE_WISHLIST_RESET = 'Remove Wishlist  Reset';

export const CLEAR_WISHLIST = 'Clear Wishlists';


export class LoadWishlist extends StateUtils.EntityLoadAction {
  readonly type = LOAD_WISHLIST;
  constructor(public payload: { userId: string; pk: string }) {
    super(DOEHLER_WISHLIST_ENTITIES, payload.pk);
  }
}

export class LoadWishlistFail extends StateUtils.EntityFailAction {
  readonly type = LOAD_WISHLIST_FAIL;
  constructor(public payload: { userId: string;  pk: string; error: any }) {
    super(DOEHLER_WISHLIST_ENTITIES, payload.pk, payload.error);
  }
}

export class LoadWishlists extends StateUtils.EntityLoadAction {
  readonly type = LOAD_WISHLISTS;
  constructor(
    public payload: {
      userId: string;
      params: DoehlerWishListSearchConfig;
    }
  ) {
    super(
      DOEHLER_WISHLIST_LIST,
      StateUtils.serializeSearchConfig(payload.params)
    );
  }
}

export class LoadWishlistsPopup extends StateUtils.EntityLoadAction {
  readonly type = LOAD_WISHLISTS_POPUP;
  constructor(
    public payload: {
      userId: string;
      params: DoehlerWishListSearchConfig;
    }
  ) {
    super(
      DOEHLER_WISHLIST_LIST_POPUP,
      StateUtils.serializeSearchConfig(payload.params)
    );
  }
}

export class LoadWishlistsMediaList extends StateUtils.EntityLoadAction {
  readonly type = LOAD_WISHLISTSMEDIALIST;
  constructor(
      public payload: {
        userId: string;
        params: DoehlerWishListSearchConfig;
      }
  ) {
    super(
        DOEHLER_WISHLIST_LIST_MEDIA_LIST,
        StateUtils.serializeSearchConfig(payload.params)
    );
  }
}

export class LoadWishlistsFail extends StateUtils.EntityFailAction {
  readonly type = LOAD_WISHLISTS_FAIL;
  constructor(public payload: { params: DoehlerWishListSearchConfig; error: any }) {
    super(
      DOEHLER_WISHLIST_LIST,
      StateUtils.serializeSearchConfig(payload.params),
      payload.error
    );
  }
}

export class LoadWishlistsMediaListFail extends StateUtils.EntityFailAction {
  readonly type = LOAD_WISHLISTS_MEDIA_LIST_FAIL;
  constructor(public payload: { params: DoehlerWishListSearchConfig; error: any }) {
    super(
        DOEHLER_WISHLIST_LIST_MEDIA_LIST,
        StateUtils.serializeSearchConfig(payload.params),
        payload.error
    );
  }
}

export class LoadWishlistSuccess extends StateUtils.EntitySuccessAction {
  readonly type = LOAD_WISHLIST_SUCCESS;
  constructor(public payload: DoehlerWishListData | DoehlerWishListData[]) {
    super(
      DOEHLER_WISHLIST_ENTITIES,
      Array.isArray(payload)
        ? payload.map((whishlist) => whishlist?.pk)
        : payload?.pk
    );
  }
}

export class LoadWishlistMediaListSuccess extends StateUtils.EntitySuccessAction {
  readonly type = LOAD_WISHLIST_MEDIALIST_SUCCESS;
  constructor(public payload: DoehlerWishListMediaListData | DoehlerWishListMediaListData[]) {
    super(
        DOEHLER_WISHLIST_MEDIALIST_ENTITIES,
        Array.isArray(payload)
            ? payload.map((wishlistmedialist) => wishlistmedialist?.mediaPath)
            : payload?.mediaPath
    );
  }
}


export class LoadWishlistsSuccess extends StateUtils.EntitySuccessAction {
  readonly type = LOAD_WISHLISTS_SUCCESS;
  constructor(
    public payload: {
      page: ListModel;
      params: DoehlerWishListSearchConfig;
    }
  ) {
    super(
      DOEHLER_WISHLIST_LIST,
      StateUtils.serializeSearchConfig(payload.params)
    );
  }
}

export class LoadWishlistsMediaListSuccess extends StateUtils.EntitySuccessAction {
  readonly type = LOAD_WISHLISTS_MEDIALIST_SUCCESS;
  constructor(
      public payload: {
        page: ListModel;
        params: DoehlerWishListSearchConfig;
      }
  ) {
    super(
        DOEHLER_WISHLIST_LIST_MEDIA_LIST,
        StateUtils.serializeSearchConfig(payload.params)
    );
  }
}




export type WishlistsAction =
  | LoadWishlist
  | LoadWishlistFail
  | LoadWishlistSuccess
  | LoadWishlists
  | LoadWishlistsPopup
  | LoadWishlistsFail
  | LoadWishlistsSuccess
  | LoadWishlistsMediaListFail
  | LoadWishlistMediaListSuccess
  | LoadWishlistsMediaListSuccess
