import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { faAngleDoubleRight, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
// TODO:Spartacus - Many methods from 'UserService' were removed, for more details please look into 4.0 migration documentation.
import {
  AuthService,
  EntitiesModel, OccConfig, OccEndpointsService, UserIdService, UserService
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DoehlerWishListSearchConfig } from 'src/app/app.module';
import { DoehlerFunctionUtil } from 'src/app/doehler-util/doehler-function-util';
import { ModalService } from 'src/app/modal/modal.service';
import { environment } from 'src/environments/environment';
import { DoehlerWishListData, DoehlerWishListMediaListData } from '../core/model';
import { DoehlerWishListService } from '../core/services';

declare let gigya: any;
declare global {
  interface Window {
    onGigyaServiceReady: any;
  }
}

@Component({
  selector: 'doehler-wishlist-page',
  templateUrl: './wishlist-page.component.html',
  styleUrls: ['./wishlist-page.component.scss']
})
export class WishlistPageComponent implements OnInit, AfterViewInit {
  gigyaReady: Promise<void>;
  env = environment;
  faFilter = faFilter;
  faAngleDoubleRight = faAngleDoubleRight;
  faTimes = faTimes;
  baseUrlMachine = '';
  showCategories: boolean;
  showSolutions: boolean;
  groupTabToShow = '';
  tasteTabToShow = '';
  isOpen: boolean;
  value: string;
  protected PAGE_SIZE = 100;
  doehlerWishListList$: Observable<EntitiesModel<DoehlerWishListData>>;
  doehlerWishListMediaList$: Observable<EntitiesModel<DoehlerWishListMediaListData>>;
  renameCollectionForm: UntypedFormGroup;
  deleteCollectionForm: UntypedFormGroup;
  moveToCollectionForm: UntypedFormGroup;
  createCollectionForm: UntypedFormGroup;
  createFavoriteForm: UntypedFormGroup;
  removeFromCollection: UntypedFormGroup;
  removeCollectionForm: UntypedFormGroup;
  activeTabName='General Collection';
  activePagination=1;
  activeTabPk;
  activeWishlistName;
  moveToProductCode;
  favouriteProductCode;
  constructor(
      protected userService: UserService,
      protected userIdService: UserIdService,
      protected occEndpoints: OccEndpointsService,
      protected http: HttpClient,
      private config: OccConfig,
      protected authService:AuthService,
      protected doehlerWishListService: DoehlerWishListService,
      private fb: UntypedFormBuilder, private router: Router, private modalService: ModalService
  ) {
    const url = new URL(window.location.href);
    localStorage.setItem('next-page-url',url.toString());

    //if (!url.hostname.includes('localhost')) {
    this.userIdService.getUserId().pipe(take(1)).subscribe((userId) => {
      try{
        const exp = localStorage.getItem("spartacus-exp");
        var expnumb: number = +exp;
        if (userId.includes('anonymous')  || Date.now() >= expnumb * 1000) {

          localStorage.removeItem('spartacus-accountID');
          localStorage.removeItem('spartacus-customerID');
          localStorage.removeItem('spartacus-exp');
          this.authService.loginWithRedirect();

        } else if (localStorage.getItem("spartacus⚿⚿auth").includes('userId')) {
          localStorage.setItem('userId', userId)
        }
      }catch (ex){
        console.log("Error "+ex);
      }
    });

    //set callback for the CDC Web SDK loading event and resolve promise
    this.gigyaReady = new Promise((resolve, reject) => {

      window.onGigyaServiceReady = function () {

        resolve();

      };

    })

    this.gigyaReady.then(() => {

      // set active organization

      if (typeof gigya !== 'undefined') {

        gigya.accounts.b2b.getOrganizationContext({

          callback: function (res) {
            const oldAccountId = localStorage.getItem('spartacus-accountID');
            
            if (res.status === 'FAIL') {
              localStorage.setItem('spartacus-accountID', undefined);
               return false;
            }

            if (typeof oldAccountId !== 'undefined' && oldAccountId != res.bpId){
              localStorage.setItem('spartacus-accountID', res.bpId);
            }
            
          },

        });

      } 

    });

    
  }
  ngAfterViewInit(): void {
    const subscription = this.doehlerWishListList$.pipe(take(1)).subscribe(obj => {
      
      if (!obj) {
         return;
      }
      
      const value = obj.values;
      for (let i=0; i < value.length; i++) {
        const products = value[i].products;
        DoehlerFunctionUtil.fnScrollIntoActivePoduct(products);
      }
      subscription.unsubscribe();
    });
  }
  ngOnInit(): void {
    this.fetchDoehlerWishListListPage({});
    //When the page is loaded for the first time, General Collection is opened, so we have to give it the active PK. it is always the 0th value
    if(this.doehlerWishListList$ != undefined && this.doehlerWishListList$ !=null){
      this.doehlerWishListList$.pipe(take(1)).subscribe(wishlist => {
        if (wishlist && wishlist.values && wishlist.values.length > 0 ){
          if(!this.activeTabPk) {
            this.activeTabPk=wishlist.values[0].pk;
          }
        if(!this.activeTabName) {
          this.activeTabName = wishlist.values[0].name;
        }
        var that = this;//Since the word "this" cannot be used in the function, such an assignment has been made.
        var selectedWhisList = wishlist.values.filter(function(item){ return item.pk == that.activeTabPk});
        if(selectedWhisList.length != 0){
          if (selectedWhisList[0].paginationProducts){
            if (this.activePagination > selectedWhisList[0].paginationProducts.length){
              this.activePagination = 1;
            }
          }
        }
      }
      });
    }
    this.renameCollectionForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.deleteCollectionForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.moveToCollectionForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.createCollectionForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.removeFromCollection = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.removeCollectionForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.createFavoriteForm = this.fb.group({
      userId: [localStorage.getItem('userId')],
    })
    this.baseUrlMachine = this.env.baseUrl
  }
  getDeviceTypeR(){
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
        /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            ua
        )
    ) {
      return "mobile";
    }
    return "desktop";
  }
  protected fetchDoehlerWishListListPage(searchConfig: DoehlerWishListSearchConfig): void {
    const wishlistParams =  { customerUserId: '', companyId:'',sort: 'code' };
    searchConfig.customerUserId=localStorage.getItem('userId');
    searchConfig.pageSize = this.PAGE_SIZE;
    searchConfig.companyId=localStorage.getItem('spartacus-accountID');
    wishlistParams.customerUserId=localStorage.getItem('userId');
    wishlistParams.companyId=localStorage.getItem('spartacus-accountID');
    this.doehlerWishListService.loadWhishlists(searchConfig);
    this.doehlerWishListList$ = this.doehlerWishListService.getList(wishlistParams);
    this.doehlerWishListMediaList$ = this.doehlerWishListService.getListMediaList(wishlistParams);//PageMediaService
  }
  getBackofficeImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${this.config.backend.occ.baseUrl}${sImgSrcUrl})`,
    };
  }
  // PDP /p/productcode/ olarak acilabilir
  getCustomProductDetailUrl(
      similarProductCode: string,
      similarProductName: string,
  ): string {
    const encodeName = encodeURIComponent(similarProductName);
    return  /p/+`${similarProductCode}`;
  }
  onChange() {
    const subOpt = document.getElementById('options') as HTMLElement;
    const label = document.getElementById('labelWrapperMobile') as HTMLElement;
    if(subOpt.classList.contains('active')) {
      subOpt.classList.remove('active')
      label.classList.remove('active')
    } else {
      subOpt.classList.add('active')
      label.classList.add('active')
    }
  }
  onChangeSubItem(productCode:string) {
    this.moveToProductCode=productCode;
    const subOption = document.getElementById('subState-'+this.moveToProductCode) as HTMLElement;
    const sublabel = document.getElementById('subLabelWrapper-'+this.moveToProductCode) as HTMLElement;
    const subOpt = document.getElementById('state') as HTMLElement;
    const label = document.getElementById('labelWrapperMobile') as HTMLElement;
    if(subOption.classList.contains('sub-active')) {
      subOption.classList.remove('sub-active')
      sublabel.classList.remove('sub-active')
    } else {
      subOption.classList.add('sub-active')
      sublabel.classList.add('sub-active')
    }
  }
  sendMessageParentIframe(window, message, url) {
    if ('parentIFrame' in window) {
      const myParentIFrame: any = window["parentIFrame"];
      myParentIFrame.sendMessage(message, url);
    }
  }
  changeTab(name: string,wishlistPk: string) {
    this.activeTabName = name;
    this.activeTabPk=wishlistPk;
    this.activePagination= 1;
    if(this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-test.doehler.com/');
    }
    if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-dev.doehler.com/');
    }
    if(!this.baseUrlMachine.includes('test') && !this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal.doehler.com/');
    }
  }
  closeSubOptions() {
    const subOpt = document.getElementById('state') as HTMLElement;
    const label = document.getElementById('labelWrapper') as HTMLElement;
    const main = document.getElementById('top') as HTMLElement;
    if(main.classList.contains('active')) {
      subOpt.classList.remove('active')
      label.classList.remove('active')
      main.classList.remove('active')
    } else {
      subOpt.classList.add('active')
      label.classList.add('active')
      main.classList.add('active')
    }
  }
  @ViewChild('widgetsContent',{static:false}) widgetsContent: ElementRef;
  activeSlide = 0;
  scrollLeft(slides){
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft - 200), behavior: 'smooth' });
    if (this.activeSlide === 1) {
      document.getElementById('moveLeft').classList.add('disabled');
    } else {
      document.getElementById('moveRight').classList.remove('disabled');
    }
    this.activeSlide--;
  }
  scrollRight(slides){
    this.widgetsContent.nativeElement.scrollTo({ left: (this.widgetsContent.nativeElement.scrollLeft + 200), behavior: 'smooth' });
    document.getElementById('moveLeft').classList.remove('active')
    if (this.activeSlide === slides.length-4) {
      document.getElementById('moveRight').classList.add('disabled');
    } else {
      document.getElementById('moveLeft').classList.remove('disabled');
    }
    this.activeSlide++;
  }

  @ViewChild('widgetsContentMobile',{static:false}) widgetsContentMobile: ElementRef;
  scrollLeftMobile(slides){
    this.widgetsContentMobile.nativeElement.scrollTo({ left: (this.widgetsContentMobile.nativeElement.scrollLeft - 150), behavior: 'smooth' });
    if (this.activeSlide === 1) {
      document.getElementById('moveLeftMobile').classList.add('disabled');
    } else {
      document.getElementById('moveRightMobile').classList.remove('disabled');
    }
    this.activeSlide--;
  }
  scrollRightMobile(slides){
    this.widgetsContentMobile.nativeElement.scrollTo({ left: (this.widgetsContentMobile.nativeElement.scrollLeft + 150), behavior: 'smooth' });
    document.getElementById('moveLeftMobile').classList.remove('active');
    if (this.activeSlide === slides.length-1) {
      document.getElementById('moveRightMobile').classList.add('disabled');
    } else {
      document.getElementById('moveLeftMobile').classList.remove('disabled');
    }
    this.activeSlide++;
  }
  renameFavoriteCollection(id: string,wishlistPk:string) {
    this.activeTabPk=wishlistPk;
    if(document.getElementById('createErrorDesktop').classList.contains('active')) {
      document.getElementById('createErrorDesktop').classList.remove('active');
    }
    if (document.getElementById('createErrorMobile').classList.contains('active')) {
      document.getElementById('createErrorMobile').classList.remove('active');
    }
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.open(id);
  }
  submitRenameForm() {
    const form: HTMLFormElement = document.querySelector('#renameWishlistForm');
    const formData = new FormData(form);
    const text = formData.get('wishlistNamePost') as string;
    this.renameCollectionForm = this.fb.group({
      wishlistCode: [this.activeTabPk],
      wishlistName: [text],
      productCode: [],
      productName: [],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    const toAdd = JSON.stringify(this.renameCollectionForm.value);
    //post yapılacak occ servisimin urli
    var createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/renameWishList`;
    //servis post edilirken kullanılacak header
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    // Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(createJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                form.reset();
                if(response==="Cannot create name with same name"){
                  document.getElementById('textErrorDesktop').classList.add('active');
                  document.getElementById('textErrorMobile').classList.add('active');
                }else if(response!=="Cannot create name with same name") {
                  document.getElementById('textErrorDesktop').classList.remove('active');
                  document.getElementById('textErrorMobile').classList.remove('active');
                  this.toggleCloseModal("requestRenameCollection");
                  this.activeTabName = text;
                  this.ngOnInit();
                }
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });
  }
  deleteFavoriteCollection(id:string,wishlistPk:string,wishlistName:string) {
    this.activeWishlistName=wishlistName;
    this.activeTabPk=wishlistPk;
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.open("removeCollection")
  }
  deleteFavoriteCollectionSubmit() {
    this.removeCollectionForm = this.fb.group({
      wishlistCode: [this.activeTabPk],
      wishlistName: [],
      productCode: [],
      productName: [],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    document.getElementById('removeCollection').style.display = "none";
    document.getElementById('requestToRemoveFromFavoriteList').style.display = "none";
    if (document.getElementById('removeCollection').style.display === "none") {
      document.getElementById('requestToRemoveFromFavoriteList').style.display = "block";
    }
    const toAdd = JSON.stringify(this.removeCollectionForm.value);
    //post yapılacak occ servisimin urli
    var createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/deleteWishList`;
    //servis post edilirken kullanılacak header
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(createJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                var that = this;
                setTimeout (function() {
                  document.getElementById('requestToRemoveFromFavoriteList').style.display = "none";
                  that.activeTabName = null;
                  that.activeTabPk = null;
                  that.toggleCloseModal("removeCollection");
                  that.ngOnInit();
                },500)
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });
  }
  moveToCollection(id:string,productCode:string){
    this.moveToProductCode=productCode;
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.open(id);
  }
  moveToCollectionSubmit(){
    const form: HTMLFormElement = document.querySelector('#moveCollectionForm');
    const formData = new FormData(form);
    const newPk = formData.get('wishlistCodePost') as string;
    this.moveToCollectionForm = this.fb.group({
      wishlistCode: [this.activeTabPk],
      wishlistName: [newPk],//yeni seçilen pk
      productCode: [this.moveToProductCode],
      productName: [],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    const toAdd = JSON.stringify(this.moveToCollectionForm.value);
    //post yapılacak occ servisimin urli
    var createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/moveToWishList`;
    //servis post edilirken kullanılacak header
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(createJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                form.reset();
                this.moveToProductCode = null;
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                this.toggleCloseModal("moveToCollection");
                this.ngOnInit();
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });
  }
  createCollection(id: string) {
    if(document.getElementById('createErrorDesktop').classList.contains('active')) {
      document.getElementById('createErrorDesktop').classList.remove('active');
    }
    if (document.getElementById('createErrorMobile').classList.contains('active')) {
      document.getElementById('createErrorMobile').classList.remove('active');
    }
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.open(id);
  }
  submitCreateCollectionForm() {
    const form: HTMLFormElement = document.querySelector('#createCollectionForm');
    const formData = new FormData(form);
    const text = formData.get('wishlistNamePost') as string;
    this.createCollectionForm = this.fb.group({
      wishlistCode: [],
      wishlistName: [text],
      productCode: [this.moveToProductCode],
      productName: [],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    const toAdd = JSON.stringify(this.createCollectionForm.value);
    //post yapılacak occ servisimin urli
    var createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/createWishList`;
    //servis post edilirken kullanılacak header
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(createJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                form.reset();
                if(response==="Cannot create name with same name") {
                  document.getElementById('createErrorDesktop').classList.add('active');
                  document.getElementById('createErrorMobile').classList.add('active');
                }else {
                  document.getElementById('createErrorDesktop').classList.remove('active');
                  document.getElementById('createErrorMobile').classList.remove('active');
                  this.toggleCloseModal("requestCreateCollection");
                  this.ngOnInit();
                }
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });
  }
  createFavorite(id: string) {
    document.getElementById('moveToCollection').style.display = "none";
    if(document.getElementById('createFavErrorDesktop').classList.contains('active')) {
      document.getElementById('createFavErrorDesktop').classList.remove('active');
    }
    if (document.getElementById('createFavErrorDesktop').classList.contains('active')) {
      document.getElementById('createFavErrorMobile').classList.remove('active');
    }
    if(document.getElementById('moveToCollection').style.display === "none") {
      document.getElementById('requestCreateFavorite').style.display = "block";
      if (this.baseUrlMachine.includes('test')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      } else if(this.baseUrlMachine.includes('dev')) {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      } else {
        this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
        this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      }
      this.modalService.open(id);
    }
  }
  submitCreateForm() {
    const form: HTMLFormElement = document.querySelector('#createFavoriteForm');
    const formData = new FormData(form);
    const text = formData.get('wishlistNamePost') as string;
    this.createFavoriteForm = this.fb.group({
      wishlistCode: [this.activeTabPk],
      wishlistName: [text],
      productCode: [this.moveToProductCode],
      productName: [],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    const toAdd = JSON.stringify(this.createFavoriteForm.value);
    //post yapılacak occ servisimin urli
    var createJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/moveToWishListCreate`;
    //servis post edilirken kullanılacak header
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(createJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                form.reset();
                this.moveToProductCode = null;
                if(response==="Cannot create name with same name") {
                  document.getElementById('createFavErrorDesktop').classList.add('active');
                  document.getElementById('createFavErrorMobile').classList.add('active');
                }else {
                  document.getElementById('createFavErrorDesktop').classList.remove('active');
                  document.getElementById('createFavErrorMobile').classList.remove('active');
                  this.toggleCloseModal("requestCreateFavorite");
                  this.ngOnInit();
                }
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });
  }

  favouriteProductName;
  removeFavorite(id:string, code: string,name:string) {
    this.favouriteProductName =name
    this.favouriteProductCode=code
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.open("removeFromFavorite");
  }
  
  removeConfirmFavorite(id:string) {

    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "openFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
   
    const form: HTMLFormElement = document.querySelector('#removeFromWishlistForm');
    const formData = new FormData(form);
    const wishListCode = formData.get('wishlistCodePost') as string;
    this.removeFromCollection = this.fb.group({
      wishlistCode: [wishListCode],
      wishlistName: [],
      productCode: [this.favouriteProductCode],
      productName: [],
      site: ["dCatalog"],
      loginCustomerUserId: [localStorage.getItem('userId')],
      companyId: [localStorage.getItem('spartacus-accountID')]
    });
    const toAdd = JSON.stringify(this.removeFromCollection.value);
    //post yapılacak occ servisimin urli
    var removeJsonWishListURL = `${this.occEndpoints.getBaseUrl()}/wishList/removeProductWishList`;
    //servis post edilirken kullanılacak header
    var httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    document.getElementById('removeFromFavorite').style.display = "none";
    //Post metodu -> then(response)-> response bizim post dan donen response degerimiz
    let promise = new Promise<void>((resolve, reject) => {
      this.http.post(removeJsonWishListURL, toAdd, httpOptions)
          .toPromise()
          .then(
              response => { // Success
                resolve();
                form.reset();
                this.favouriteProductCode = null ;
                if ( document.getElementById('addToFav-'+this.favouriteProductCode) != null ){
                  document.getElementById('addToFav-'+this.favouriteProductCode).classList.remove('addedToFavorite');
                  document.getElementById('addToFav-'+this.favouriteProductCode).classList.add('fav');
                }
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                var that = this;
                setTimeout (function() {
                  
                  that.ngOnInit();
                  
                },500)
              },
              msg => { // Error
                if (this.baseUrlMachine.includes('test')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
                } else if(this.baseUrlMachine.includes('dev')) {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
                } else {
                  this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
                }
                reject(msg);
              }
          )
    });


    if (promise) {
      this.modalService.open(id);
    }
  }
  openModal(id: string) {
    this.modalService.open(id);
  }
  closeModal(id: string) {
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.close(id);
  }
  toggleCloseModal(id: string) {
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "closeFilter"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
    }
    this.modalService.close(id);
  }
  goTo(target: string) {
    console.log(target);
  }
  showGroupTab(name: string) {
    // const currentGroup = this.doehlerWishList$.find(x => x.name === name);
    // this.groupTabToShow = name;
    // this.tasteTabToShow = currentGroup.products[0].name;
  }
  showTasteTab(name: string) {
    this.tasteTabToShow = name;
  }
  toggleFilterBar() {
    const myFlyIn = document.getElementById('filterFlyIn') as HTMLElement;
    if (myFlyIn.classList.contains('visible')) {
      myFlyIn.classList.remove('visible');
    } else {
      myFlyIn.classList.add('visible');
    }
  }
  setBackgroundImage(sImgSrcUrl: string): object {
    return {
      'background-image': `url(${sImgSrcUrl})`
    };
  }
  setBackgroundColour(sColourCode: string): object {
    if (sColourCode === 'brown') {
      sColourCode = 'rgb(117, 52, 21)';
    } else if (sColourCode === 'black') {
      sColourCode = 'black';
    } else if (sColourCode === 'orange') {
      sColourCode = 'rgb(251, 120, 80)';
    } else if (sColourCode === 'violet') {
      sColourCode = '#a200c7';
    } else if (sColourCode === 'yellow' || sColourCode === 'yellow-orange') {
      sColourCode = 'rgb(254, 231, 99)';
    } else if (sColourCode === 'blue') {
      sColourCode = 'rgb(81, 102, 167)';
    } else if (sColourCode === 'green') {
      sColourCode = 'rgb(92, 176, 60)';
    } else if (sColourCode === 'red' || sColourCode === 'red-blue') {
      sColourCode = 'rgb(206, 0, 56)';
    } else if (sColourCode === 'white' || sColourCode === 'colorless') {
      sColourCode = 'white';
    } else if (sColourCode === 'pink') {
      sColourCode = '#ff52eb';
    } else if (sColourCode === 'glitter') {
      sColourCode = '#f5f5f5';
    }
    return {
      'background-color': `${sColourCode}`
    };
  }
  //Used when making page changes
  changePagination(pagintionKey: number) {
    this.activePagination = pagintionKey;
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal.doehler.com/');
    }
  }
  changePaginationActive(pagintionKey: number) {
    this.activePagination = pagintionKey;
    if (this.baseUrlMachine.includes('test')) {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-test.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-test.doehler.com/');
    } else if(this.baseUrlMachine.includes('dev')) {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal-dev.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal-dev.doehler.com/');
    } else {
      this.sendMessageParentIframe(window, '{"inquiry": "scrollToTop"}', 'https://d-portal.doehler.com/');
      this.sendMessageParentIframe(window, '{"inquiry": "setHeight"}', 'https://d-portal.doehler.com/');
    }
  }
}