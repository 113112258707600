import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  EntitiesModel,
  OccEndpointsService,
  SearchConfig
} from '@spartacus/core';
import { Observable } from 'rxjs';
import {
  WISHLISTS_MEDIALIST_NORMALIZER, WISHLISTS_NORMALIZER,
  WISHLIST_NORMALIZER
} from '../../core/connectors/converters';
import { DoehlerWishlistAdapter } from '../../core/connectors/wishlist.adapter';
import {
  DoehlerWishListData,
  DoehlerWishListList,
  DoehlerWishListMediaListData,
  DoehlerWishListMediaListList
} from '../../core/model/wishlist.model';


@Injectable()
export class OccDoehlerWishListAdapter implements DoehlerWishlistAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {}

  load(userid: string, pk: string): Observable<DoehlerWishListData> {
    return this.http
    .get<DoehlerWishListData>(
      this.getDoehlerWishListEndpoint(pk)
    )
    .pipe(this.converter.pipeable(WISHLIST_NORMALIZER));

  }

  loadList(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>> {
    return this.http
    .get<DoehlerWishListList>(
      this.getDoehlerWishListsEndpoint(userId,companyId, params)
    ).pipe(this.converter.pipeable(WISHLISTS_NORMALIZER));
  }

  loadPopupList(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListData>> {
    return this.http
    .get<DoehlerWishListList>(
      this.getDoehlerWishListsPopupEndpoint(userId,companyId, params)
    ).pipe(this.converter.pipeable(WISHLISTS_NORMALIZER));
  }

  loadListMediaList(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): Observable<EntitiesModel<DoehlerWishListMediaListData>> {
    return this.http
        .get<DoehlerWishListMediaListList>(
            this.getDoehlerWishListsMediaListEndpoint(userId,companyId, params)
        ).pipe(this.converter.pipeable(WISHLISTS_MEDIALIST_NORMALIZER));
  }

  protected getDoehlerWishListEndpoint(
    pk:string,
  ): string {
    return this.occEndpoints.buildUrl('wishList/details',  { urlParams: { pk } });
  }

  protected getDoehlerWishListsEndpoint(
    userId: string,
    companyId: string,
    params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/list',  { urlParams: { userId,companyId } ,queryParams: params});
  }

  protected getDoehlerWishListsPopupEndpoint(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/list-popup', {urlParams: {userId, companyId}, queryParams: params});
  }

  protected getDoehlerWishListsMediaListEndpoint(
      userId: string,
      companyId: string,
      params?: SearchConfig
  ): string {
    return this.occEndpoints.buildUrl('wishList/wishlistmedialist',  { urlParams: { userId,companyId },queryParams: params });
  
  }
  
}
