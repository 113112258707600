import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { provideDefaultConfig } from '@spartacus/core';
import {
  WISHLISTS_NORMALIZER, WISHLIST_NORMALIZER, WISHLISTS_MEDIALIST_NORMALIZER,
} from '../core/connectors/converters';
import { DoehlerWishlistAdapter } from '../core/connectors/wishlist.adapter';
import { OccDoehlerWishListAdapter } from './adapters/occ-doehler-wishlist.adapter';
import { doehlerWishlistOccConfig } from './config/doehler-wishlist-occ-config';
import { OccDoehlerWishListsListNormalizer } from './converters/occ-doehler-wishlist-list-normalizer';
import { OccDoehlerWishListNormalizer } from './converters/occ-doehler-wishlist-normalizer';
import {OccDoehlerWishListsMediaListNormalizer} from "./converters/occ-doehler-wishlist-media-list-normalizer";


@NgModule({
  imports: [CommonModule],
  providers: [
    provideDefaultConfig(doehlerWishlistOccConfig),
    {
      provide: DoehlerWishlistAdapter,
      useClass: OccDoehlerWishListAdapter,
    },
    
    {
      provide: WISHLIST_NORMALIZER,
      useExisting: OccDoehlerWishListNormalizer,
      multi: true,
    },
    {
      provide: WISHLISTS_NORMALIZER,
      useExisting: OccDoehlerWishListsListNormalizer,
      multi: true,
    },
    {
      provide: WISHLISTS_MEDIALIST_NORMALIZER,
      useExisting: OccDoehlerWishListsMediaListNormalizer,
      multi: true,
    },
    
  ],
})
export class DoehlerWishListOccModule {}
