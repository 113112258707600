import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, provideConfig, provideDefaultConfig, RoutingConfig, UrlModule } from '@spartacus/core';
import {
  CmsPageGuard, FacetModule, IconModule,
  KeyboardFocusModule, ListNavigationModule, MediaModule, PageLayoutComponent, PaginationModule, ProductListComponent, PRODUCT_LISTING_URL_MATCHER
} from '@spartacus/storefront';
import { ClickOutsideModule } from "ng-click-outside";
import { NpnSliderModule } from "npn-slider";
import { ModalModule } from "../modal";
import { categoryTranslationChunksConfig, categoryTranslations } from './assets/translations/translations';
import { CustomCategoryListComponent } from './custom-category-list/custom-category-list.component';
import { CustomProductLeftRefinementComponent } from './custom-product-left-refinement/custom-product-left-refinement.component';

@NgModule({
  declarations: [CustomCategoryListComponent, CustomProductLeftRefinementComponent],
    imports: [

      RouterModule.forChild([
        {
          // @ts-ignore
          path: null,
          canActivate: [CmsPageGuard],
          component: PageLayoutComponent,
          data: { pageLabel: 'search', cxRoute: 'search' },
        },
        {
          // @ts-ignore
          path: null,
          canActivate: [CmsPageGuard],
          component: PageLayoutComponent,
          data: { cxRoute: 'brand' },
        },
        {
          // The 'category' route  may include a greedy suffix url matcher '**/c/:categoryCode'
          // So not to shadow the specific 'brand' route, the 'category' is the last route in the sequence.
          // @ts-ignore
          path: null,
          canActivate: [CmsPageGuard],
          component: PageLayoutComponent,
          data: { cxRoute: 'category' },
        },
      ]),
      
        CommonModule,
        PaginationModule,
        FontAwesomeModule,
        IconModule,
        RouterModule,
        UrlModule,
        NpnSliderModule,
        KeyboardFocusModule,
        ListNavigationModule,
        MediaModule,
        ClickOutsideModule,
        I18nModule,
        ConfigModule.withConfig({
            cmsComponents: {
                ProductListComponent: {
                    component: CustomCategoryListComponent,
                },
                CMSProductListComponent: {
                    component: CustomCategoryListComponent,
                },
                SearchResultsListComponent: {
                    component: ProductListComponent,
                },/* ,
        ProductRefinementComponent: {
          component: CustomProductLeftRefinementComponent,
        }, */
            },
        } as CmsConfig),
        FacetModule,
        ModalModule,
        ReactiveFormsModule,
    ],
    providers: [
      provideDefaultConfig(<RoutingConfig>{
        routing: {
          routes: {
            category: {
              matchers: [PRODUCT_LISTING_URL_MATCHER],
            },
          },
        },
      }),
      provideConfig(<I18nConfig>{
        i18n: {
          resources: categoryTranslations,
          chunks: categoryTranslationChunksConfig,
          fallbackLang: 'en',
        },
      }),
    ],
  bootstrap: []
})
export class DoehlerCustomCategoryModule { }

