<div class="content-wrapper" id="top">
    <div class="inner">
        <div class="favourite-breadcrumbs mobile">
            <ul>
                <ng-container *ngIf="!this.baseUrlMachine.includes('dev') && !this.baseUrlMachine.includes('test')">
                    <li><a href="https://d-portal.doehler.com/product-catalogue/start"
                            target="_parent">Home</a><span>&nbsp;>&nbsp;</span></li>
                </ng-container>
                <ng-container *ngIf="this.baseUrlMachine.includes('test')">
                    <li>
                        <a href="https: //d-portal-test.doehler.com/product-catalogue/start" target="_parent">
                            Home</a><span>&nbsp;>&nbsp;</span>
                    </li>
                </ng-container>
                <ng-container *ngIf="this.baseUrlMachine.includes('dev')">
                    <li><a href="https://d-portal-dev.doehler.com/product-catalogue/start"
                            target="_parent">Home</a><span>&nbsp;>&nbsp;</span></li>
                </ng-container>
                <ng-container>
                    <li>My Favorite Products</li>
                </ng-container>
            </ul>
        </div>
        <section class="wishlist-product-filter">
            <ng-container *ngIf="doehlerWishListMediaList$ | async as mediaList">
                <ng-container *ngIf="mediaList.values.length > 0">
                    <ng-container *ngFor="let mediaListData of mediaList.values">
                        <div *ngIf="getDeviceTypeR() === mediaListData.mediaFormat">
                            <div class="teaser key-visual" [ngStyle]="getBackofficeImage(mediaListData.mediaPath)">
                                <div class="teaser-content">
                                    <div class="inner">
                                        <div class="wrapper">
                                            <div class="inline-items">
                                                <h3>My favourite products</h3>
                                                <span>Everything in one place-please find here all products you have
                                                    bookmarked.</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <div class="tabs-area">
                <div class="inner">
                    <div class="wrapper-mobile">
                        <div class="inline-items">
                            <span>Everything in one place-please find here all products you have bookmarked.</span>
                        </div>
                        <div class="buttonRow favoriteButton-mobile">
                            <button type="button" class="create" (click)="createCollection('requestCreateCollection')">
                                <div class="create-img"></div><span class="button-name">Create a collection</span>
                            </button>
                        </div>
                    </div>
                    <div class="tabs-row inner desktop">
                        <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                            <ng-container *ngIf="wishlists.values.length > 4">
                                <button class="icon-arrow-left active" id="moveLeft"
                                    (click)="scrollLeft(wishlists.values)"></button>
                            </ng-container>
                        </ng-container>
                        <div #widgetsContent class="custom-slider-main" id="customCollectionSlider">
                            <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                                <ng-container *ngIf="wishlists.values.length == 0">
                                    <div routerLink="./" id="collection-all"
                                        [ngClass]="{'active': this.activeTabName === 'General Collection'}"
                                        (click)="changeTab('General Collection','General Collection')"
                                        class="collection-tab">
                                        <p>General&nbsp;Collection&nbsp;<span>0</span></p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="wishlists.values.length > 0">
                                    <ng-container *ngFor="let item of wishlists.values">
                                        <div routerLink="./" class="collection-tab"
                                            [ngClass]="{'active': this.activeTabName === item.name}"
                                            (click)="changeTab(item.name,item.pk)" id="tab-{{item.pk}}">
                                            <p class="item-name">
                                                {{item.name}}&nbsp;<span>{{item.products.length}}</span></p>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                            <ng-container *ngIf="wishlists.values.length > 4">
                                <button class="icon-arrow-right" id="moveRight"
                                    (click)="scrollRight(wishlists.values)"></button>
                            </ng-container>
                        </ng-container>
                        <div class="space"></div>
                        <div class="buttonRow favoriteButton-desktop">
                            <button type="button" class="create" (click)="createCollection('requestCreateCollection')">
                                <div class="create-img"></div><span class="button-name">Create a collection</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="inner-mobile">
                    <div class="tabs-row inner mobile">
                        <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                            <ng-container *ngIf="wishlists.values.length >= 2">
                                <button class="icon-arrow-left active" id="moveLeftMobile"
                                    (click)="scrollLeftMobile(wishlists.values)"></button>
                            </ng-container>
                        </ng-container>
                        <div #widgetsContentMobile class="custom-slider-main" id="customCollectionSliderMobile">
                            <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                                <ng-container *ngIf="wishlists.values.length == 0">
                                    <div routerLink="./" id="collection-all"
                                        [ngClass]="{'active': this.activeTabName === 'General Collection'}"
                                        (click)="changeTab('General Collection','General Collection')"
                                        class="collection-tab">
                                        <p>General&nbsp;Collection&nbsp;<span>0</span></p>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="wishlists.values.length > 0">
                                    <ng-container *ngFor="let item of wishlists.values">
                                        <div routerLink="./" class="collection-tab"
                                            [ngClass]="{'active': this.activeTabName === item.name}"
                                            (click)="changeTab(item.name,item.pk)" id="tab-{{item.pk}}">
                                            <p class="item-name">
                                                {{item.name}}&nbsp;<span>{{item.products.length}}</span></p>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                        </div>
                        <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                            <ng-container *ngIf="wishlists.values.length >= 2">
                                <button class="icon-arrow-right" id="moveRightMobile"
                                    (click)="scrollRightMobile(wishlists.values)"></button>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
                <div class="table-header-mobile">
                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                        <ng-container *ngIf="wishlists.values.length == 0">
                            <div class="header-items">
                                <h5>General Collection</h5>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wishlists.values.length > 0">
                            <ng-container *ngFor="let item of wishlists.values">
                                <ng-container *ngIf="this.activeTabName === item.name">
                                    <div class="header-items">
                                        <h5 id="header-{{item.pk}}">{{item.name}}</h5>
                                        <ng-container *ngIf="item.name !== 'General Collection'">
                                            <div class="buttonRow">
                                                <div class="rename"
                                                    (click)="renameFavoriteCollection('requestRenameCollection',item.pk)">
                                                    <img class="img-rename" src="/assets/icons/update.svg" />Rename
                                                    collection
                                                </div>
                                                <div class="delete"
                                                    (click)="deleteFavoriteCollection('removeCollection',item.pk,item.name)">
                                                    <img class="img-delete" src="/assets/icons/delete.svg" />Delete
                                                    collection
                                                </div>
                                            </div>
                                            <div id="labelWrapperMobile">
                                                <div class="items-label" id="itemLabel" (click)="onChange()">
                                                    <div class="options" id="options">
                                                        <div class="optionValue-rename"
                                                            (click)="renameFavoriteCollection('requestRenameCollection',item.pk)">
                                                            <img class="img-rename"
                                                                src="/assets/icons/update.svg" />Rename collection
                                                        </div>
                                                        <div class="optionValue-delete"
                                                            (click)="deleteFavoriteCollection('removeCollection',item.pk,item.name)">
                                                            <img class="img-delete"
                                                                src="/assets/icons/delete.svg" />Delete collection
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="table-header-items">
                                            <div class="items-update">
                                                <ng-container *ngIf="item.paginationProducts == undefined">
                                                    <div class="items-number">
                                                        <span class="last-items">Items: </span>
                                                        <span>0</span>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="item.paginationProducts !=undefined">
                                                    <ng-container *ngFor="let pagination of item.paginationProducts">
                                                        <ng-container *ngIf="this.activePagination === pagination.key">
                                                            <div class="items-number">
                                                                <span class="last-items">Items: </span>
                                                                <span>{{pagination.value.productList.length}}</span>
                                                            </div>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                                <div class="last-changed">
                                                    <span class="last-change">Last change: </span>
                                                    <span>{{item.modifiedDate}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="inner">
                <div class="seperation-section">
                    <div class="tabs-content outer">
                        <div class="tabs-content">
                            <div class="content-area">
                                <div class="table-header-desktop">
                                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                                        <ng-container *ngIf="wishlists.values.length == 0">
                                            <div class="header-items">
                                                <h5>General Collection</h5>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="wishlists.values.length > 0">
                                            <ng-container *ngFor="let item of wishlists.values">
                                                <ng-container *ngIf="this.activeTabName === item.name">
                                                    <div class="header-items">
                                                        <h5 id="header-{{item.pk}}">{{item.name}}</h5>
                                                        <ng-container *ngIf="item.name !== 'General Collection'">
                                                            <div class="buttonRow">
                                                                <div class="rename"
                                                                    (click)="renameFavoriteCollection('requestRenameCollection',item.pk)">
                                                                    <img class="img-rename"
                                                                        src="/assets/icons/update.svg" />Rename
                                                                    collection
                                                                </div>
                                                                <div class="delete"
                                                                    (click)="deleteFavoriteCollection('removeCollection',item.pk,item.name)">
                                                                    <img class="img-delete"
                                                                        src="/assets/icons/delete.svg" />Delete
                                                                    collection
                                                                </div>
                                                            </div>
                                                        </ng-container>
                                                        <div class="table-header-items">
                                                            <div class="items-update">
                                                                <ng-container
                                                                    *ngIf="item.paginationProducts == undefined">
                                                                    <div class="items-number">
                                                                        <span class="last-items">Items: </span>
                                                                        <span>0</span>
                                                                    </div>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="item.paginationProducts !=undefined">
                                                                    <ng-container
                                                                        *ngFor="let pagination of item.paginationProducts">
                                                                        <ng-container
                                                                            *ngIf="this.activePagination === pagination.key">
                                                                            <div class="items-number">
                                                                                <span class="last-items">Items: </span>
                                                                                <span>{{pagination.value.productList.length}}</span>
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <div class="last-changed">
                                                                    <span class="last-change">Last change: </span>
                                                                    <span>{{item.modifiedDate}}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div class="row">
                                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                                        <ng-container *ngIf="wishlists.values.length == 0">
                                            <div class="header-items empty">
                                                <p>You don't have any products in your wishlist.</p>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngFor="let wishlist of wishlists.values">
                                            <ng-container *ngIf="this.activeTabName === wishlist.name">
                                                <ng-container *ngIf="wishlist.paginationProducts !=undefined">
                                                    <ng-container
                                                        *ngFor="let pagination of wishlist.paginationProducts">
                                                        <ng-container *ngIf="this.activePagination === pagination.key">
                                                            <ng-container
                                                                *ngFor="let product of pagination.value.productList">
                                                                <ng-container
                                                                    *ngIf="product.doehlerCatalogConfigData != null">
                                                                    <ng-container
                                                                        *ngIf="product.doehlerCatalogConfigData.filterFacetDCatalog[0] != undefined">
                                                                        <ng-container
                                                                            *ngIf="product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.code != 'DCatalogClassification/1.0/PRODUCT_DETAILS.rmw61zf01'">
                                                                            <div id="itemBox-{{product.code}}"
                                                                                class="item-box"
                                                                                [ngClass]="{ blocked: 'check' == product.approvalStatus || 'unapproved' == product.approvalStatus }">
                                                                                <div class="border"
                                                                                    style="display: none"></div>
                                                                                <div class="divide-content">
                                                                                    <div class="details">
                                                                                        <div class="detail-content">
                                                                                            <div class="product-id">
                                                                                                {{product.code}}</div>
                                                                                            <div class="buttonRow">
                                                                                                <button id="addedToFav"
                                                                                                    type="button"
                                                                                                    (click)="removeFavorite('removeFromFavorite',product.code,product.name)">
                                                                                                    <div
                                                                                                        class="favorite-img">
                                                                                                    </div>
                                                                                                </button>
                                                                                                <div id="subLabelWrapper-{{product.code}}"
                                                                                                    class="subLabelWrapper"
                                                                                                    *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                                    <div class="sub-items-label"
                                                                                                        id="subItemLabel-{{product.code}}">
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div id="subLabelWrapper-{{product.code}}"
                                                                                                    class="subLabelWrapper"
                                                                                                    *ngIf="'approved' === product.approvalStatus">
                                                                                                    <div class="sub-items-label"
                                                                                                        id="subItemLabel-{{product.code}}"
                                                                                                        (click)="onChangeSubItem(product.code)">
                                                                                                        <div class="subOptions"
                                                                                                            id="subState-{{product.code}}"
                                                                                                            name="state">
                                                                                                            <div
                                                                                                                class="switch-img">
                                                                                                            </div>
                                                                                                            <div class="subOptionValue"
                                                                                                                id="subOptionValue-{{product.code}}"
                                                                                                                (click)="moveToCollection('moveToCollection',product.code)">
                                                                                                                Move to
                                                                                                                collection
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h3
                                                                                        *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                        {{product.name}}</h3>
                                                                                    <div class="not-available"
                                                                                        *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                        <div class="item-image"></div>
                                                                                        <p>Item is not available anymore
                                                                                        </p>
                                                                                    </div>
                                                                                    <a [href]="getCustomProductDetailUrl(product.code,product.name)"
                                                                                        style="text-decoration: none;"
                                                                                        *ngIf="'approved' === product.approvalStatus">
                                                                                        <h3>{{product.name}}</h3>
                                                                                        <div class="attribute-list"
                                                                                            *ngIf="'approved' === product.approvalStatus || 'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                            <ul>
                                                                                                <ng-container
                                                                                                    *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                    <li class="attribute-name"
                                                                                                        *ngIf="i<4">
                                                                                                        <div
                                                                                                            class="attribute-category-details">
                                                                                                            {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="attribute-details">
                                                                                                            <ng-container
                                                                                                                *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                    <span>{{featureValues.value.replace('to',
                                                                                                                        '&mdash;').replace('from',
                                                                                                                        '')}}</span>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                    <span>{{featureValues.value}}</span>
                                                                                                                </ng-container>
                                                                                                            </ng-container>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ng-container>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </a>
                                                                                    <div class="attribute-list"
                                                                                        *ngIf="'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                        <ul>
                                                                                            <ng-container
                                                                                                *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                <li class="attribute-name"
                                                                                                    *ngIf="i<4">
                                                                                                    <div
                                                                                                        class="attribute-category-details">
                                                                                                        {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="attribute-details">
                                                                                                        <ng-container
                                                                                                            *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                <span>{{featureValues.value.replace('to',
                                                                                                                    '&mdash;').replace('from',
                                                                                                                    '')}}</span>
                                                                                                            </ng-container>
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                <span>{{featureValues.value}}</span>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ng-container>
                                                                        <ng-container
                                                                            *ngIf="product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.code == 'DCatalogClassification/1.0/PRODUCT_DETAILS.rmw61zf01'">
                                                                            <ng-container
                                                                                *ngIf="product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'red-blue' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'red' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'pink' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'yellow-orange' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'yellow' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'blue' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'green' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'black' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'brown' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'white' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'violet' &&
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value != 'orange'">
                                                                                <div id="itemBox-{{product.code}}"
                                                                                    class="item-box"
                                                                                    [ngClass]="{ blocked: 'check' == product.approvalStatus || 'unapproved' == product.approvalStatus }">
                                                                                    <div class="border"
                                                                                        style="display: none"></div>
                                                                                    <div class="divide-content">
                                                                                        <div class="details">
                                                                                            <div class="detail-content">
                                                                                                <div class="product-id">
                                                                                                    {{product.code}}
                                                                                                </div>
                                                                                                <div class="buttonRow">
                                                                                                    <button
                                                                                                        id="addedToFav"
                                                                                                        type="button"
                                                                                                        (click)="removeFavorite('removeFromFavorite',product.code,product.name)">
                                                                                                        <div
                                                                                                            class="favorite-img">
                                                                                                        </div>
                                                                                                    </button>
                                                                                                    <div id="subLabelWrapper-{{product.code}}"
                                                                                                        class="subLabelWrapper"
                                                                                                        *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                                        <div class="sub-items-label"
                                                                                                            id="subItemLabel-{{product.code}}">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div id="subLabelWrapper-{{product.code}}"
                                                                                                        class="subLabelWrapper"
                                                                                                        *ngIf="'approved' === product.approvalStatus">
                                                                                                        <div class="sub-items-label"
                                                                                                            id="subItemLabel-{{product.code}}"
                                                                                                            (click)="onChangeSubItem(product.code)">
                                                                                                            <div class="subOptions"
                                                                                                                id="subState-{{product.code}}"
                                                                                                                name="state">
                                                                                                                <div
                                                                                                                    class="switch-img">
                                                                                                                </div>
                                                                                                                <div class="subOptionValue"
                                                                                                                    id="subOptionValue-{{product.code}}"
                                                                                                                    (click)="moveToCollection('moveToCollection',product.code)">
                                                                                                                    Move
                                                                                                                    to
                                                                                                                    collection
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <h3
                                                                                            *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                            {{product.name}}</h3>
                                                                                        <div class="not-available"
                                                                                            *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                            <div class="item-image">
                                                                                            </div>
                                                                                            <p>Item is not available
                                                                                                anymore</p>
                                                                                        </div>
                                                                                        <a [href]="getCustomProductDetailUrl(product.code,product.name)"
                                                                                            style="text-decoration: none;"
                                                                                            *ngIf="'approved' === product.approvalStatus">
                                                                                            <h3>{{product.name}}</h3>
                                                                                            <div class="attribute-list"
                                                                                                *ngIf="'approved' === product.approvalStatus || 'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                                <ul>
                                                                                                    <ng-container
                                                                                                        *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                        <li class="attribute-name"
                                                                                                            *ngIf="i<4">
                                                                                                            <div
                                                                                                                class="attribute-category-details">
                                                                                                                {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="attribute-details">
                                                                                                                <ng-container
                                                                                                                    *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                                    <ng-container
                                                                                                                        *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                        <span>{{featureValues.value.replace('to',
                                                                                                                            '&mdash;').replace('from',
                                                                                                                            '')}}</span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container
                                                                                                                        *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                        <span>{{featureValues.value}}</span>
                                                                                                                    </ng-container>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ng-container>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </a>
                                                                                        <div class="attribute-list"
                                                                                            *ngIf="'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                            <ul>
                                                                                                <ng-container
                                                                                                    *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                    <li class="attribute-name"
                                                                                                        *ngIf="i<4">
                                                                                                        <div
                                                                                                            class="attribute-category-details">
                                                                                                            {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="attribute-details">
                                                                                                            <ng-container
                                                                                                                *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                    <span>{{featureValues.value.replace('to',
                                                                                                                        '&mdash;').replace('from',
                                                                                                                        '')}}</span>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                    <span>{{featureValues.value}}</span>
                                                                                                                </ng-container>
                                                                                                            </ng-container>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ng-container>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>
                                                                            <ng-container
                                                                                *ngIf="product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'red-blue' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'red' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'pink' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'yellow-orange' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'yellow' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'blue' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'green' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'black' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'brown' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'white' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'violet' ||
                                                                    product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value == 'orange'">
                                                                                <div id="itemBox-{{product.code}}"
                                                                                    class="item-box"
                                                                                    [ngClass]="{ blocked: 'check' == product.approvalStatus || 'unapproved' == product.approvalStatus }">
                                                                                    <div class="border"
                                                                                        [ngStyle]="setBackgroundColour(product.doehlerCatalogConfigData.filterFacetDCatalog[0].featureData.featureValues[0].value)">
                                                                                    </div>
                                                                                    <div class="divide-content">
                                                                                        <div class="details">
                                                                                            <div class="detail-content">
                                                                                                <div class="product-id">
                                                                                                    {{product.code}}
                                                                                                </div>
                                                                                                <div class="buttonRow">
                                                                                                    <button
                                                                                                        id="addedToFav"
                                                                                                        type="button"
                                                                                                        (click)="removeFavorite('removeFromFavorite',product.code,product.name)">
                                                                                                        <div
                                                                                                            class="favorite-img">
                                                                                                        </div>
                                                                                                    </button>
                                                                                                    <div id="subLabelWrapper-{{product.code}}"
                                                                                                        class="subLabelWrapper"
                                                                                                        *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                                        <div class="sub-items-label"
                                                                                                            id="subItemLabel-{{product.code}}">
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div id="subLabelWrapper-{{product.code}}"
                                                                                                        class="subLabelWrapper"
                                                                                                        *ngIf="'approved' === product.approvalStatus">
                                                                                                        <div class="sub-items-label"
                                                                                                            id="subItemLabel-{{product.code}}"
                                                                                                            (click)="onChangeSubItem(product.code)">
                                                                                                            <div class="subOptions"
                                                                                                                id="subState-{{product.code}}"
                                                                                                                name="state">
                                                                                                                <div
                                                                                                                    class="switch-img">
                                                                                                                </div>
                                                                                                                <div class="subOptionValue"
                                                                                                                    id="subOptionValue-{{product.code}}"
                                                                                                                    (click)="moveToCollection('moveToCollection',product.code)">
                                                                                                                    Move
                                                                                                                    to
                                                                                                                    collection
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <h3
                                                                                            *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                            {{product.name}}</h3>
                                                                                        <div class="not-available"
                                                                                            *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                            <div class="item-image">
                                                                                            </div>
                                                                                            <p>Item is not available
                                                                                                anymore</p>
                                                                                        </div>
                                                                                        <a [href]="getCustomProductDetailUrl(product.code,product.name)"
                                                                                            style="text-decoration: none;"
                                                                                            *ngIf="'approved' === product.approvalStatus">
                                                                                            <h3>{{product.name}}</h3>
                                                                                            <div class="attribute-list"
                                                                                                *ngIf="'approved' === product.approvalStatus || 'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                                <ul>
                                                                                                    <ng-container
                                                                                                        *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                        <li class="attribute-name"
                                                                                                            *ngIf="i<4">
                                                                                                            <div
                                                                                                                class="attribute-category-details">
                                                                                                                {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="attribute-details">
                                                                                                                <ng-container
                                                                                                                    *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                                    <ng-container
                                                                                                                        *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                        <span>{{featureValues.value.replace('to',
                                                                                                                            '&mdash;').replace('from',
                                                                                                                            '')}}</span>
                                                                                                                    </ng-container>
                                                                                                                    <ng-container
                                                                                                                        *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                        <span>{{featureValues.value}}</span>
                                                                                                                    </ng-container>
                                                                                                                </ng-container>
                                                                                                            </div>
                                                                                                        </li>
                                                                                                    </ng-container>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </a>
                                                                                        <div class="attribute-list"
                                                                                            *ngIf="'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                            <ul>
                                                                                                <ng-container
                                                                                                    *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                    <li class="attribute-name"
                                                                                                        *ngIf="i<4">
                                                                                                        <div
                                                                                                            class="attribute-category-details">
                                                                                                            {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                        </div>
                                                                                                        <div
                                                                                                            class="attribute-details">
                                                                                                            <ng-container
                                                                                                                *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                    <span>{{featureValues.value.replace('to',
                                                                                                                        '&mdash;').replace('from',
                                                                                                                        '')}}</span>
                                                                                                                </ng-container>
                                                                                                                <ng-container
                                                                                                                    *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                    <span>{{featureValues.value}}</span>
                                                                                                                </ng-container>
                                                                                                            </ng-container>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ng-container>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </ng-container>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <ng-container
                                                                        *ngIf="product.doehlerCatalogConfigData.filterFacetDCatalog[0] == undefined">
                                                                        <div id="itemBox-{{product.code}}"
                                                                            class="item-box"
                                                                            [ngClass]="{ blocked: 'check' == product.approvalStatus || 'unapproved' == product.approvalStatus }">
                                                                            <div class="border" style="display: none">
                                                                            </div>
                                                                            <div class="divide-content">
                                                                                <div class="details">
                                                                                    <div class="detail-content">
                                                                                        <div class="product-id">
                                                                                            {{product.code}}</div>
                                                                                        <div class="buttonRow">
                                                                                            <button id="addedToFav"
                                                                                                type="button"
                                                                                                (click)="removeFavorite('removeFromFavorite',product.code,product.name)">
                                                                                                <div
                                                                                                    class="favorite-img">
                                                                                                </div>
                                                                                            </button>
                                                                                            <div id="subLabelWrapper-{{product.code}}"
                                                                                                class="subLabelWrapper"
                                                                                                *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                                <div class="sub-items-label"
                                                                                                    id="subItemLabel-{{product.code}}">
                                                                                                </div>
                                                                                            </div>
                                                                                            <div id="subLabelWrapper-{{product.code}}"
                                                                                                class="subLabelWrapper"
                                                                                                *ngIf="'approved' === product.approvalStatus">
                                                                                                <div class="sub-items-label"
                                                                                                    id="subItemLabel-{{product.code}}"
                                                                                                    (click)="onChangeSubItem(product.code)">
                                                                                                    <div class="subOptions"
                                                                                                        id="subState-{{product.code}}"
                                                                                                        name="state">
                                                                                                        <div
                                                                                                            class="switch-img">
                                                                                                        </div>
                                                                                                        <div class="subOptionValue"
                                                                                                            id="subOptionValue-{{product.code}}"
                                                                                                            (click)="moveToCollection('moveToCollection',product.code)">
                                                                                                            Move to
                                                                                                            collection
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <h3
                                                                                    *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                    {{product.name}}</h3>
                                                                                <div class="not-available"
                                                                                    *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                    <div class="item-image"></div>
                                                                                    <p>Item is not available anymore</p>
                                                                                </div>
                                                                                <a [href]="getCustomProductDetailUrl(product.code,product.name)"
                                                                                    style="text-decoration: none;"
                                                                                    *ngIf="'approved' === product.approvalStatus">
                                                                                    <h3>{{product.name}}</h3>
                                                                                    <div class="attribute-list"
                                                                                        *ngIf="'approved' === product.approvalStatus || 'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                        <ul>
                                                                                            <ng-container
                                                                                                *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                                <li class="attribute-name"
                                                                                                    *ngIf="i<4">
                                                                                                    <div
                                                                                                        class="attribute-category-details">
                                                                                                        {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="attribute-details">
                                                                                                        <ng-container
                                                                                                            *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                                <span>{{featureValues.value.replace('to',
                                                                                                                    '&mdash;').replace('from',
                                                                                                                    '')}}</span>
                                                                                                            </ng-container>
                                                                                                            <ng-container
                                                                                                                *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                                <span>{{featureValues.value}}</span>
                                                                                                            </ng-container>
                                                                                                        </ng-container>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ng-container>
                                                                                        </ul>
                                                                                    </div>
                                                                                </a>
                                                                                <div class="attribute-list"
                                                                                    *ngIf="'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                    <ul>
                                                                                        <ng-container
                                                                                            *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                            <li class="attribute-name"
                                                                                                *ngIf="i<4">
                                                                                                <div
                                                                                                    class="attribute-category-details">
                                                                                                    {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                </div>
                                                                                                <div
                                                                                                    class="attribute-details">
                                                                                                    <ng-container
                                                                                                        *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                        <ng-container
                                                                                                            *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                            <span>{{featureValues.value.replace('to',
                                                                                                                '&mdash;').replace('from',
                                                                                                                '')}}</span>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                            <span>{{featureValues.value}}</span>
                                                                                                        </ng-container>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ng-container>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </ng-container>
                                                                </ng-container>
                                                                <ng-container
                                                                    *ngIf="product.doehlerCatalogConfigData == null">
                                                                    <div id="itemBox-{{product.code}}" class="item-box"
                                                                        [ngClass]="{ blocked: 'check' == product.approvalStatus || 'unapproved' == product.approvalStatus }">
                                                                        <div class="border" style="display: none"></div>
                                                                        <div class="divide-content">
                                                                            <div class="details">
                                                                                <div class="detail-content">
                                                                                    <div class="product-id">
                                                                                        {{product.code}}</div>
                                                                                    <div class="buttonRow">
                                                                                        <button id="addedToFav"
                                                                                            type="button"
                                                                                            (click)="removeFavorite('removeFromFavorite',product.code,product.name)">
                                                                                            <div class="favorite-img">
                                                                                            </div>
                                                                                        </button>
                                                                                        <div id="subLabelWrapper-{{product.code}}"
                                                                                            class="subLabelWrapper"
                                                                                            *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                            <div class="sub-items-label"
                                                                                                id="subItemLabel-{{product.code}}">
                                                                                            </div>
                                                                                        </div>
                                                                                        <div id="subLabelWrapper-{{product.code}}"
                                                                                            class="subLabelWrapper"
                                                                                            *ngIf="'approved' === product.approvalStatus">
                                                                                            <div class="sub-items-label"
                                                                                                id="subItemLabel-{{product.code}}"
                                                                                                (click)="onChangeSubItem(product.code)">
                                                                                                <div class="subOptions"
                                                                                                    id="subState-{{product.code}}"
                                                                                                    name="state">
                                                                                                    <div
                                                                                                        class="switch-img">
                                                                                                    </div>
                                                                                                    <div class="subOptionValue"
                                                                                                        id="subOptionValue-{{product.code}}"
                                                                                                        (click)="moveToCollection('moveToCollection',product.code)">
                                                                                                        Move to
                                                                                                        collection</div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <h3
                                                                                *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                {{product.name}}</h3>
                                                                            <div class="not-available"
                                                                                *ngIf="'check' == product.approvalStatus || 'unapproved' == product.approvalStatus ">
                                                                                <div class="item-image"></div>
                                                                                <p>Item is not available anymore</p>
                                                                            </div>
                                                                            <a [href]="getCustomProductDetailUrl(product.code,product.name)"
                                                                                style="text-decoration: none;"
                                                                                *ngIf="'approved' === product.approvalStatus">
                                                                                <h3>{{product.name}}</h3>
                                                                                <div class="attribute-list"
                                                                                    *ngIf="product.doehlerCatalogConfigData && ('approved' === product.approvalStatus || 'check' === product.approvalStatus || 'unapproved' === product.approvalStatus)">
                                                                                    <ul>
                                                                                        <ng-container
                                                                                            *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                            <li class="attribute-name"
                                                                                                *ngIf="i<4">
                                                                                                <div
                                                                                                    class="attribute-category-details">
                                                                                                    {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                                </div>
                                                                                                <div
                                                                                                    class="attribute-details">
                                                                                                    <ng-container
                                                                                                        *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                        <ng-container
                                                                                                            *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                            <span>{{featureValues.value.replace('to',
                                                                                                                '&mdash;').replace('from',
                                                                                                                '')}}</span>
                                                                                                        </ng-container>
                                                                                                        <ng-container
                                                                                                            *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                            <span>{{featureValues.value}}</span>
                                                                                                        </ng-container>
                                                                                                    </ng-container>
                                                                                                </div>
                                                                                            </li>
                                                                                        </ng-container>
                                                                                    </ul>
                                                                                </div>
                                                                            </a>
                                                                            <div class="attribute-list"
                                                                                *ngIf="'check' === product.approvalStatus || 'unapproved' === product.approvalStatus">
                                                                                <ul>
                                                                                    <ng-container
                                                                                        *ngFor="let productDetailProductHighlightDCatalog of product.doehlerCatalogConfigData.productDetailProductHighlightDCatalog; let i=index">
                                                                                        <li class="attribute-name"
                                                                                            *ngIf="i<4">
                                                                                            <div
                                                                                                class="attribute-category-details">
                                                                                                {{productDetailProductHighlightDCatalog.featureData.name}}
                                                                                            </div>
                                                                                            <div
                                                                                                class="attribute-details">
                                                                                                <ng-container
                                                                                                    *ngFor="let featureValues of productDetailProductHighlightDCatalog.featureData.featureValues">
                                                                                                    <ng-container
                                                                                                        *ngIf="featureValues.value.indexOf(' to ') > -1">
                                                                                                        <span>{{featureValues.value.replace('to',
                                                                                                            '&mdash;').replace('from',
                                                                                                            '')}}</span>
                                                                                                    </ng-container>
                                                                                                    <ng-container
                                                                                                        *ngIf="featureValues.value.indexOf(' to ') == -1">
                                                                                                        <span>{{featureValues.value}}</span>
                                                                                                    </ng-container>
                                                                                                </ng-container>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ng-container>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-container>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--Pagination Start-->
        <div class="row slp-pagination col-auto d-flex justify-content-center py-3">
            <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                <ng-container *ngFor="let wishlist of wishlists.values">
                    <ng-container *ngIf="this.activeTabName === wishlist.name">
                        <ng-container *ngIf="wishlist.paginationProducts !=undefined">
                            <ng-container *ngIf="wishlist.paginationProducts.length >= 2">
                                <button class="start" (click)="changePagination(1)"> «</button>
                                <ng-container *ngFor="let pagination of wishlist.paginationProducts">
                                    <ng-container *ngIf="this.activePagination == pagination.key">
                                        <button (click)="changePaginationActive(pagination.key)" id="pagination"
                                            class="pagination-active">{{pagination.key}}</button>
                                    </ng-container>
                                    <ng-container *ngIf="this.activePagination != pagination.key">
                                        <button (click)="changePaginationActive(pagination.key)"
                                            id="pagination">{{pagination.key}}</button>
                                    </ng-container>
                                </ng-container>
                                <button class="end" (click)="changePagination(wishlist.paginationProducts.length)">
                                    »</button>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </ng-container>
        </div>
        <!--Pagination END-->
    </div>
</div>
<jw-modal id="requestRenameCollection" class="wideModal">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('requestRenameCollection')"></button>
    </div>
    <div class="jw-modal-inner" id="plpCreateModal">
        <h3>Change collection name</h3>
        <div id="plpCreateCollection">
            <form id="renameWishlistForm" autocomplete="off" [formGroup]="renameCollectionForm">
                <div class="form-group">
                    <h4>Collection Name</h4>
                    <input type="text" id="saveWishlistName" name="wishlistNamePost" placeholder="New collection name"
                        maxlength="20" class="form-control">
                </div>
                <div id="desktop" class="buttonRow desktop-favoriteButtons">
                    <label id="textErrorDesktop" class="wishlist-label" for="saveWishlistName">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestRenameCollection')">Cancel</button>
                        <button (click)="submitRenameForm()" class="submitButton">Save</button>
                    </div>
                </div>
                <div id="mobile" class="buttonRow mobile-favoriteButtons">
                    <label id="textErrorMobile" class="wishlist-label" for="saveWishlistName">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button (click)="submitRenameForm()" class="submitButton">Save</button>
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestRenameCollection')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="moveToCollection" class="wideModal">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('moveToCollection')"></button>
    </div>
    <div class="jw-modal-inner" id="plpModal">
        <h3 class="move-to-collection">Move to collection</h3>
        <p class="please-select-the-collection">Please select the collection to which the product should be move to.</p>
        <form [formGroup]="moveToCollectionForm" id="moveCollectionForm">
            <div id="plpAddToCollection">
                <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                    <ng-container *ngIf="wishlists.values.length == 0">
                        <div class="form-group">
                            <label class="radio-inline">
                                <input class="form-control" id="saveWishlistCode" type="radio" name="wishlistCodePost"
                                    value="General Collection" [checked]="" />
                                <div class="collection-info">
                                    <span class="collection">General Collection</span>
                                    <div class="last-update">
                                        <div class="last-changed">Last change:
                                            <span>0</span>
                                        </div>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wishlists.values.length > 0">
                        <ng-container *ngFor="let item of wishlists.values">
                            <div class="form-group">
                                <label class="radio-inline">
                                    <input class="form-control wishlist-class" id="saveWishlistCode" type="radio"
                                        name="wishlistCodePost" value="{{item.pk}}" [checked]="" />
                                    <div class="collection-info">
                                        <span class="collection">{{item.name}}</span>
                                        <div class="last-update">
                                            <div class="last-changed">Last change:
                                                <span>{{item.modifiedDate}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>
            <div class="buttonRow favoriteButtons-desktop">
                <button type="button" class="create" (click)="createFavorite('requestCreateFavorite')">
                    <div class="create-img"></div><span class="button-name">Create a collection</span>
                </button>
            </div>
            <div class="buttonRow desktop-favoriteButtons">
                <div class="button-container">
                    <button type="cancel" class="cancel" (click)="closeModal('moveToCollection')">Cancel</button>
                    <button (click)="moveToCollectionSubmit()" class="submitButton">Move</button>
                </div>
            </div>
            <div class="buttonRow mobile-favoriteButtons">
                <div class="button-container">
                    <button (click)="moveToCollectionSubmit()" class="submitButton">Move</button>
                    <button type="cancel" class="cancel" (click)="closeModal('moveToCollection')">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</jw-modal>
<jw-modal id="requestCreateCollection">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('requestCreateCollection')"></button>
    </div>
    <div class="jw-modal-inner" id="plpCreateModal">
        <h3>Create a collection</h3>
        <div id="plpCreateCollection">
            <form id="createCollectionForm" autocomplete="off" [formGroup]="createCollectionForm">
                <div class="form-group">
                    <h4>Collection Name</h4>
                    <input type="text" id="saveWishlistName" name="wishlistNamePost" placeholder="New collection name"
                        maxlength="20" class="form-control">
                </div>
                <div class="buttonRow desktop-favoriteButtons">
                    <label id="createErrorDesktop" class="wishlist-label" for="saveWishlistName">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestCreateCollection')">Cancel</button>
                        <button (click)="submitCreateCollectionForm()" class="submitButton">Save</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <label id="createErrorMobile" class="wishlist-label" for="saveWishlistName">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button (click)="submitCreateCollectionForm()" class="submitButton">Save</button>
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestCreateCollection')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="requestCreateFavorite">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('requestCreateFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plpCreateModal">
        <h3>Create a collection</h3>
        <div id="plpCreateCollection">
            <form id="createFavoriteForm" autocomplete="off" [formGroup]="createFavoriteForm">
                <div class="form-group">
                    <h4>Collection Name</h4>
                    <input type="text" id="saveWishlistNamePost" name="wishlistNamePost"
                        placeholder="New collection name" maxlength="20" class="form-control">
                </div>
                <div class="buttonRow desktop-favoriteButtons">
                    <label id="createFavErrorDesktop" class="wishlist-label" for="saveWishlistNamePost">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestCreateFavorite')">Cancel</button>
                        <button (click)="submitCreateForm()" class="submitButton">Save</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <label id="createFavErrorMobile" class="wishlist-label" for="saveWishlistNamePost">
                        <div class="item-image"></div>
                        <p>This collection name already exists.</p>
                    </label>
                    <div class="button-container">
                        <button (click)="submitCreateForm()" class="submitButton">Save</button>
                        <button type="cancel" class="cancel"
                            (click)="closeModal('requestCreateFavorite')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="removeCollection">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('removeCollection')"></button>
    </div>
    <div class="jw-modal-inner" id="plpModal">
        <h3 class="move-to-collection">Delete collection</h3>
        <div id="plpAddToCollection">
            <form [formGroup]="removeCollectionForm" id="removeWishlistForm">
                <p class="please-select-the-collection">Are you sure you want to delete the collection <span
                        class="delete-collection-name">{{this.activeWishlistName}}</span>?</p>
                <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                    <ng-container *ngIf="wishlists.values.length == 0">
                        <div class="form-group" style="display: none;">
                            <label class="radio-inline">
                                <input class="form-control" type="radio" name="wishlistCodePost"
                                    value="General Collection" [checked]="" />
                                <span class="collection">General Collection</span>
                            </label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wishlists.values.length > 0">
                        <ng-container *ngFor="let item of wishlists.values">
                            <div class="form-group" style="display: none;">
                                <label class="radio-inline">
                                    <input class="form-control wishlist-class" type="radio" name="wishlistCodePost"
                                        value="{{item.pk}}" [checked]="" />
                                    <span class="collection">{{item.name}}</span>
                                    <div class="last-update">
                                        <div class="last-changed">Last change:
                                            <span>{{item.modifiedDate}}</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="buttonRow desktop-favoriteButtons">
                    <div class="button-container">
                        <button type="cancel" class="cancel" (click)="closeModal('removeCollection')">Cancel</button>
                        <button (click)="deleteFavoriteCollectionSubmit()" class="submitButton">Delete</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <div class="button-container">
                        <button (click)="deleteFavoriteCollectionSubmit()" class="submitButton">Delete</button>
                        <button type="cancel" class="cancel" (click)="closeModal('removeCollection')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="removeFromFavorite" class="wideModal">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('removeFromFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plpModal">
        <h3>Remove From collection</h3>
        <div id="plpRemoveFromFavorite">
            <form [formGroup]="removeFromCollection" id="removeFromWishlistForm">
                <p class="please-select-the-collection">Are you sure you want to remove
                    <span>{{favouriteProductName}}</span> from your list?
                </p>
                <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                    <ng-container *ngIf="wishlists.values.length == 0">
                        <div class="form-group" style="display: none;">
                            <label class="radio-inline">
                                <input class="form-control" type="radio" name="wishlistCodePost"
                                    value="General Collection" [checked]="" />
                                <span class="collection">General Collection</span>
                            </label>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="wishlists.values.length > 0">
                        <ng-container *ngFor="let item of wishlists.values">
                            <div class="form-group" style="display: none;">
                                <label class="radio-inline">
                                    <input class="form-control wishlist-class" type="radio" name="wishlistCodePost"
                                        value="{{item.pk}}" [checked]="" />
                                    <span class="collection">{{item.name}}</span>
                                    <div class="last-update">
                                        <div class="last-changed">Last change:
                                            <span>{{item.modifiedDate}}</span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <div class="buttonRow desktop-favoriteButtons">
                    <div class="button-container">
                        <button type="cancel" class="cancel" (click)="closeModal('removeFromFavorite')">Cancel</button>
                        <button (click)="removeConfirmFavorite('removeFavorite')" class="submitButton">Apply</button>
                    </div>
                </div>
                <div class="buttonRow mobile-favoriteButtons">
                    <div class="button-container">
                        <button (click)="removeConfirmFavorite('removeFavorite')" class="submitButton">Apply</button>
                        <button type="cancel" class="cancel" (click)="closeModal('removeFromFavorite')">Cancel</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</jw-modal>
<jw-modal id="removeFavorite">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite" (click)="closeModal('removeFavorite')"></button>
    </div>
    <div class="jw-modal-inner" id="plp-createFavorite">
        <div class="form-content second-modal">
            <div class="alert-wrapper">
                <div class="alert-container">
                    <div class="alert-header">
                        <div class="alert-icon success"></div>
                        <h3>Removed from My Favorite Products</h3>
                    </div>
                </div>
            </div>
            <form [formGroup]="removeFromCollection" id="removeFromWishlistForm" style="display: none;">
                <div id="plpAddToCollection">
                    <ng-container *ngIf="doehlerWishListList$ | async as wishlists">
                        <ng-container *ngIf="wishlists.values.length == 0">
                            <div class="form-group">
                                <label class="radio-inline">
                                    <input class="form-control" type="radio" name="wishlistCodePost"
                                        value="General Collection" [checked]="" />
                                    <span class="collection">General Collection</span>
                                </label>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="wishlists.values.length > 0">
                            <ng-container *ngFor="let item of wishlists.values">
                                <div class="form-group">
                                    <label class="radio-inline">
                                        <input class="form-control wishlist-class" type="radio" name="wishlistCodePost"
                                            value="{{item.pk}}" [checked]="" />
                                        <span class="collection">{{item.name}}</span>
                                        <div class="last-update">
                                            <div class="last-changed">Last change:
                                                <span>{{item.modifiedDate}}</span>
                                            </div>
                                        </div>
                                    </label>
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
            </form>
        </div>
    </div>
</jw-modal>

<jw-modal id="requestToRemoveFromFavoriteList">
    <div class="jw-modal-close-icon">
        <button type="cancel" class="close-favorite"
            (click)="toggleCloseModal('requestToRemoveFromFavoriteList')"></button>
    </div>
    <div class="jw-modal-inner" id="plp-createFavorite">
        <div class="form-content second-modal">
            <div class="alert-wrapper">
                <div class="alert-container">
                    <div class="alert-header">
                        <div class="alert-icon success"></div>
                        <h3>Removed from My Favorite Products</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</jw-modal>