import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { translations } from "@spartacus/assets";
import { OccConfig, provideConfig, SiteContextConfig } from '@spartacus/core';
import {
    defaultCmsContentProviders, FacetListComponent,
    FacetService, layoutConfig,
    mediaConfig, ProductFacetService, ProductListComponentService
} from '@spartacus/storefront';
import { environment } from "../../environments/environment";
import { DoehlerAuthInterceptor } from "../doehler-authorization/DoehlerAuthInterceptor";
import { DoehlerTokenRevocationInterceptor } from "../doehler-authorization/DoehlerTokenRevocationInterceptor";
import { CustomProductListComponentService } from "../doehler-custom-category/custom-category-list/CustomProductListComponentService";
import { DoehlerFacetListComponent } from "../doehler-custom-category/custom-category-list/DoehlerFacetListComponent";
import { DoehlerFacetService } from "../doehler-custom-category/custom-category-list/DoehlerFacetService";
import { DoehlerProductFacetService } from "../doehler-custom-category/custom-category-list/DoehlerProductFacetService";

export const translationOverwrites = {
    en: { // lang
        common: { // chunk
            httpHandlers: { // keys (nested)
                forbidden: 'Adding items to My Favorite Products is not yet available for Döhler employees.',
            },
        },
    },
};
const occConfig: OccConfig = {
    backend: {
        occ: {
            prefix: '/occ/v2/',
            endpoints: {
                product: 'products/${productCode}?fields=FULL,productBenefits,doehlerBenefits,doehlerCatalogConfigData,applicationFitCategories,isFavourite,wishListDCatalog',
                productSearch: 'products/search?fields=FULL,doehlerCatalogConfigData,categoryCatalogTabInfo,pageName,defaultActiveTabName,categoriesMediaList,attributeGroups,wishListDCatalog,targetMarketMappingData',
            },
        },
    },
};

// only provide the `occ.baseUrl` key if it is explicitly configured, otherwise the value of
// <meta name="occ-backend-base-url"> is ignored.
// This in turn breaks the call to the API aspect in public cloud environments
if (environment.occBaseUrl) {
    occConfig.backend.occ.baseUrl = environment.occBaseUrl;
}


@NgModule({

    providers: [
        provideConfig(layoutConfig),
        provideConfig(mediaConfig),
        ...defaultCmsContentProviders,
        provideConfig(<SiteContextConfig>{
            context: {
              baseSite:[
                'dCatalog'
              ],
            },
             authentication: {
                client_id: environment.clientId, // this should be the client_id used in IAS
                client_secret: 'fakeClient', // The client secret is needed to fulfill the API contract for certain flows.
                //Never put here a real value as this is publicly available in the internet.
                baseUrl: environment.iasURL + '/oauth2', // The base URL defines, where to redirect.
                tokenEndpoint: '/token',
                loginUrl: '/authorize',
                logoutUrl: '/logout',
                OAuthLibConfig: {
                    issuer: environment.iasURL,
                    responseType: 'token',
                    scope: 'openid',
                    redirectUri: environment.baseUrl,
                    customTokenParameters: ['token_type'],
                    oidc: true,
                },
            }, 
            layoutSlots: {
                header: {
                    lg: {
                        slots: [],
                    },
                    slots: [],
                },
                DoehlerProductDetailsPageTemplate: {
                    slots: ['Section2A', 'Section2B', 'Section3', 'Section4A'],
                },
                DoehlerTestSearchPageTemplate: {
                    slots: ['Section1'],
                },
                footer: {
                    slots: [],
                },
            },
            routing: {
                protected: false, // change to true for early login
            },
            
            enableResetViewOnNavigate:{
                active:false
        
            }
        }),
        {
            provide: ProductListComponentService,
            useClass: CustomProductListComponentService,

        },
        {
            provide: FacetService,
            useClass: DoehlerFacetService,

        },
        {
            provide: ProductFacetService,
            useClass: DoehlerProductFacetService,

        },
        {
            provide: FacetListComponent,
            useClass: DoehlerFacetListComponent,

        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: DoehlerAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useExisting: DoehlerTokenRevocationInterceptor,
            multi: true,
        },
        provideConfig({
            i18n: {resources: translations},
        }),
        provideConfig({
            i18n: {resources: translationOverwrites},
        }),
    ],
})
export class SpartacusConfigurationModule {
}
