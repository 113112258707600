import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { EntitiesModel, SearchConfig, StateUtils } from "@spartacus/core";
import {DoehlerWishListData, DoehlerWishListList, DoehlerWishListMediaListData} from "../../model/wishlist.model";
import {
  WishListManagement,
  WishlistState,
  WISHLIST_FEATURE,
  WishlistMediaListState,
  WishListMediaListManagement, WISHLISTMEDIALIST_FEATURE
} from "../wishlist-state";



export const getWishlistState: MemoizedSelector<
WishlistState,
WishlistState
> = createFeatureSelector<WishlistState>(WISHLIST_FEATURE);

export const getWishlistMediaListState: MemoizedSelector<
    WishlistMediaListState,
    WishlistMediaListState
    > = createFeatureSelector<WishlistMediaListState>(WISHLISTMEDIALIST_FEATURE);

export const getWishlistManagmentState: MemoizedSelector<
WishlistState,
WishListManagement
> = createSelector(
  getWishlistState,
  (state: WishlistState) => state[WISHLIST_FEATURE]
);

export const getWishlistMediaListManagmentState: MemoizedSelector<
    WishlistMediaListState,
    WishListMediaListManagement
    > = createSelector(
    getWishlistMediaListState,
    (state: WishlistMediaListState) => state[WISHLISTMEDIALIST_FEATURE]
);

export const getWishListsState: MemoizedSelector<
WishlistState,
  StateUtils.EntityLoaderState<DoehlerWishListData>
> = createSelector(
  getWishlistManagmentState,
  (state: WishListManagement) => state && state.entities
);

export const getWhishList = (
  pk: string
): MemoizedSelector<
WishlistState,
  StateUtils.LoaderState<DoehlerWishListData>
> =>
  createSelector(
    getWishListsState,
    (state: StateUtils.EntityLoaderState<DoehlerWishListData>) =>
      StateUtils.entityLoaderStateSelector(state, pk)
  );


export const getWhishListList = (
  params: SearchConfig
): MemoizedSelector<
  WishlistState,
  StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>
> =>
  createSelector(
    getWishlistManagmentState,
    (state: WishListManagement) =>
      StateUtils.denormalizeSearch<DoehlerWishListData>(state, params)
  );

  export const getWhishListPopup = (
  params: SearchConfig
): MemoizedSelector<
  WishlistState,
  StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>
> =>
  createSelector(
    getWishlistManagmentState,
    (state: WishListManagement) =>
      StateUtils.denormalizeSearch<DoehlerWishListData>(state, params)
  );


export const getWhishListListMediaList = (
    params: SearchConfig
): MemoizedSelector<
    WishlistMediaListState,
    StateUtils.LoaderState<EntitiesModel<DoehlerWishListMediaListData>>
    > =>
    createSelector(
        getWishlistMediaListManagmentState,
        (state: WishListMediaListManagement) =>
            StateUtils.denormalizeSearch<DoehlerWishListMediaListData>(state, params)
    );