import { StateUtils } from "@spartacus/core";
import {
  DoehlerWishListData,
  DoehlerWishListList,
  DoehlerWishListMediaListData
} from "src/app/doehler-custom-wishlist/core/model/wishlist.model";


export const WISHLIST_FEATURE = 'wishlist';
export const WISHLISTMEDIALIST_FEATURE = 'wishlistmedialist';
export const DOEHLER_WISHLIST_ENTITIES = 'doehler-wishlist-entities';
export const DOEHLER_WISHLIST_MEDIALIST_ENTITIES = 'doehler-wishlist-medialist-entities';
export const DOEHLER_WISHLIST_LIST = 'doehler-wishlist-list';
export const DOEHLER_WISHLIST_LIST_POPUP = 'doehler-wishlist-list-popup'
export const DOEHLER_WISHLIST_LIST_MEDIA_LIST = 'doehler-wishlist-list-media-list';
export const REMOVE_WISHLIST_PROCESS_ID = 'removeWishlist';
export const ADD_WISHLIST_PROCESS_ID = 'addUserWishlist'

export interface WishListManagement
  extends StateUtils.EntityListState<DoehlerWishListData> {}

export interface WishListMediaListManagement
    extends StateUtils.EntityListState<DoehlerWishListMediaListData> {}

export interface WishlistState {
  [WISHLIST_FEATURE]: WishListManagement;
}

export interface WishlistMediaListState {
  [WISHLISTMEDIALIST_FEATURE]: WishListMediaListManagement;
}