import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  EntitiesModel,
  SearchConfig,
  StateUtils,
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import { Observable, queueScheduler } from 'rxjs';
import { filter, map, observeOn, pluck, tap } from 'rxjs/operators';
import { DoehlerWishListSearchConfig } from 'src/app/app.module';
import {DoehlerWishListData, DoehlerWishListList, DoehlerWishListMediaListData} from '../model/wishlist.model';
import { WishlistActions } from '../store/actions/index';
import { WishlistSelectors } from '../store/selectors';
import {WishlistMediaListState, WishlistState} from '../store/wishlist-state';
import {getWhishListListMediaList} from "../store/selectors/wishlist.selectors";
import {LoadWishlistsMediaList} from "../store/actions/wishlist.actions";

@Injectable({ providedIn: 'root' })
export class DoehlerWishListService {
  constructor(
    protected store: Store<WishlistState | StateWithProcess<void>>,
    protected storeMedia: Store<WishlistMediaListState | StateWithProcess<void>>,
    protected userIdService: UserIdService
  ) {}


  getWishListLoading(pk: string): Observable<boolean> {
    return this.getWishList(pk).pipe(pluck('loading'));
  }


  loadWhishlist(pk: string): void {
/*    this.userIdService.invokeWithUserId((userId) =>
      this.store.dispatch(
        new WishlistActions.LoadWishlist({
          userId,
          pk,
        })
      )
    );*/
  }
 
  loadWhishlists(params?: SearchConfig): void {
    const userId = localStorage.getItem('userId');
      this.store.dispatch(
        new WishlistActions.LoadWishlists({ userId, params })
      )
  
  }

  loadWhishlistsPopup(params?: SearchConfig): void {
    const userId = localStorage.getItem('userId');
    this.store.dispatch(
        new WishlistActions.LoadWishlistsPopup({userId, params})
    )

  }

  loadWhishlistsMediaList(params?: SearchConfig): void {
    const userId = localStorage.getItem('userId');
    this.store.dispatch(
        new WishlistActions.LoadWishlistsMediaList({ userId, params })
    )
  }

  private getWishList(
    pk: string
  ): Observable<StateUtils.LoaderState<DoehlerWishListData>> {
    
    return this.store.select(
      WishlistSelectors.getWhishList(pk)
    );
  }

  private getWishLists(
    params?: DoehlerWishListSearchConfig
  ): Observable<StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>> {

    const userId = localStorage.getItem('userId');
    this.store.dispatch(
      new WishlistActions.LoadWishlists({ userId,params })
    )
    return this.store.select(
      WishlistSelectors.getWhishListList(params)
    );
  }

  private getWishListsPopup(
      params?: DoehlerWishListSearchConfig
  ): Observable<StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>> {

    const userId = localStorage.getItem('userId');
    this.store.dispatch(
        new WishlistActions.LoadWishlistsPopup({userId, params})
    )
    return this.store.select(
        WishlistSelectors.getWhishListPopup(params)
    );
  }

  private getWishListsMediaList(
      params?: DoehlerWishListSearchConfig
  ): Observable<StateUtils.LoaderState<EntitiesModel<DoehlerWishListMediaListData>>> {

    const userId = localStorage.getItem('userId');
    this.storeMedia.dispatch(
        new WishlistActions.LoadWishlistsMediaList({ userId,params })
    )
    return this.storeMedia.select(
        WishlistSelectors.getWhishListListMediaList(params)
    );
  }

  get(pk: string): Observable<DoehlerWishListData> {
    return this.getWishList(pk).pipe(
      observeOn(queueScheduler),
      tap((state) => {
        if (!(state.loading || state.success || state.error)) {
          this.loadWhishlist(pk);
        }
      }),
      filter((state) => state.success || state.error),
      map((state) => state.value)
    );
  }

  getList(params: SearchConfig): Observable<EntitiesModel<DoehlerWishListData>> {
    return this.getWishLists(params).pipe(
      observeOn(queueScheduler),
      tap((process: StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>) => {
        if (!(process.loading || process.success || process.error)) {
          this.loadWhishlists(params);
        }
      }),
      filter(
        (process: StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>) =>
          process.success || process.error
      ),
      map((result) => result.value)
    );
  }

  getPopupList(params: SearchConfig): Observable<EntitiesModel<DoehlerWishListData>> {
    return this.getWishListsPopup(params).pipe(
      observeOn(queueScheduler),
      tap((process: StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>) => {
        if (!(process.loading || process.success || process.error)) {
          this.loadWhishlistsPopup(params);
        }
      }),
      filter(
        (process: StateUtils.LoaderState<EntitiesModel<DoehlerWishListData>>) =>
          process.success || process.error
      ),
      map((result) => result.value)
    );
  }

  getListMediaList(params: SearchConfig): Observable<EntitiesModel<DoehlerWishListMediaListData>> {
    return this.getWishListsMediaList(params).pipe(
        observeOn(queueScheduler),
        tap((process: StateUtils.LoaderState<EntitiesModel<DoehlerWishListMediaListData>>) => {
          if (!(process.loading || process.success || process.error)) {
            this.loadWhishlistsMediaList(params);
          }
        }),
        filter(
            (process: StateUtils.LoaderState<EntitiesModel<DoehlerWishListMediaListData>>) =>
                process.success || process.error
        ),
        map((result) => result.value)
    );
  }

  

  
}
