import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {effects, effectsMedia} from './effects/index';
import {WISHLIST_FEATURE, WISHLISTMEDIALIST_FEATURE} from './wishlist-state';
import {
  metaReducers,
  reducerMediaListProvider,
  reducerMediaListToken,
  reducerProvider,
  reducerToken
} from './reducers/index';

@NgModule({
  imports: [
    StoreModule.forFeature(WISHLIST_FEATURE, reducerToken, {
      metaReducers,
    }),
    EffectsModule.forFeature(effects),
    StoreModule.forFeature(WISHLISTMEDIALIST_FEATURE, reducerMediaListToken, {
      metaReducers,
    }),
    //EffectsModule.forFeature(effectsMedia),
  ],
  providers: [reducerProvider,reducerMediaListProvider],
})
export class DoehlerWishListStoreModule {}