import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import {
    BrowserModule,
    BrowserTransferStateModule
} from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { translationChunksConfig, translations } from '@spartacus/assets';
import {
    FeaturesConfig,
    I18nConfig,
    OccConfig,
    provideConfig, SearchConfig
} from '@spartacus/core';
import { AppRoutingModule, StorefrontComponent } from '@spartacus/storefront';
import { OAuthModule } from "angular-oauth2-oidc";
import { environment } from "../environments/environment";
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { ClickOutsideModule } from 'ng-click-outside';

const devImports = [];
if (!environment.production) {
    devImports.push(StoreDevtoolsModule.instrument());
}

@NgModule({
    declarations: [AppComponent],

    imports: [
        BrowserModule.withServerTransition({appId: 'spartacus-app'}),

        BrowserTransferStateModule,
        HttpClientModule,
        AppRoutingModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        OAuthModule.forRoot(),
        SpartacusModule,
        ClickOutsideModule,
        ...devImports,

    ],

    providers: [
        provideConfig(<OccConfig>{
            backend: {
              occ: {
                baseUrl: environment.occBaseUrl
              },
            },
          }),

        provideConfig(<I18nConfig>{
            // we bring in static translations to be up and running soon right away
            i18n: {
                resources: translations,
                chunks: translationChunksConfig,
                fallbackLang: 'en',
            },
        }),
        provideConfig(<FeaturesConfig>{
            features: {
                level: '4.2',
            },
        }),
    ],
    bootstrap: [StorefrontComponent],
})
export class AppModule {
}

export interface DoehlerWishListSearchConfig extends SearchConfig {
    customerUserId?: string;
    companyId?: string;
}