import { StateUtils } from '@spartacus/core';
import {DoehlerWishListData, DoehlerWishListList, DoehlerWishListMediaListData} from '../../model/wishlist.model';
import { WishlistActions } from '../actions/index';
import {LOAD_WISHLIST_MEDIALIST_SUCCESS, LOAD_WISHLISTS_MEDIALIST_SUCCESS} from "../actions/wishlist.actions";


export const wishlistInitialState = undefined;
export const wishlistMediaListInitialState = undefined;
export const wishlistsInitialState = undefined;
export const wishlistsMediaInitialState = undefined;


export function wishlistEntitiesReducer(
  state: DoehlerWishListData = wishlistInitialState,
  action: StateUtils.LoaderAction
): DoehlerWishListData {
  switch (action.type) {
    case WishlistActions.LOAD_WISHLIST_SUCCESS:
      return action.payload;
  
  }
  return state;
}

export function wishlistMediaListEntitiesReducer(
    state: DoehlerWishListMediaListData = wishlistMediaListInitialState,
    action: StateUtils.LoaderAction
): DoehlerWishListMediaListData {
  switch (action.type) {
    case WishlistActions.LOAD_WISHLIST_MEDIALIST_SUCCESS:
      return action.payload;
  }
  return state;
}

export function wishListListReducer(
  state = wishlistsInitialState,
  action: StateUtils.LoaderAction
): any {
  switch (action.type) {

    case WishlistActions.LOAD_WISHLISTS_SUCCESS:
      return action.payload.page;
  }

  return state;
}

export function wishListListMediaReducer(
    state = wishlistsMediaInitialState,
    action: StateUtils.LoaderAction
): any {
  switch (action.type) {

    case WishlistActions.LOAD_WISHLISTS_MEDIALIST_SUCCESS:
      return action.payload.page;
  }

  return state;
}
